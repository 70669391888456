import React, { useRef, useState } from 'react'
import styles from './ReferralModal.module.scss'
import cx from 'classnames'
import FocusTrapWrapper from 'components/FocusTrapWrapper'
import { useClickOutsideModal } from 'hooks/useClickOutsideModal'
import { validateEmail } from 'utils/validators'
import { trackSubscribeReferralCap } from 'events/index'
import { PriceManager } from 'prices'
import { Currency } from 'prices/types'
import { Img } from 'components/basic/Img'
import { REFERRAL_DISCOUNT_MAP } from 'stripe_lib/discounts'
import { Button } from 'components/Phantom/Button'
import { datadogReportError } from 'events/datadog'

interface Props {
	className?: string
	modalVisible: boolean
	handleClose: () => void
	preventMouse?: boolean
	currency: Currency
	saleName: string
}

const Wrapper: React.FC<Props> = (props: Props) => {
	const pageWrapper = useRef(null)
	useClickOutsideModal(pageWrapper, props.handleClose, true)

	const [email, setEmail] = useState('')
	const [name, setName] = useState('')
	const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false)
	const [loading, setIsLoading] = useState<boolean>(false)

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setIsLoading(true)

		try {
			const passesValidation: boolean = validateEmail(email)
			if (passesValidation) {
				const referralCode = sessionStorage.getItem('referralCode')
				await trackSubscribeReferralCap({ email, name, referralCode })
				sessionStorage.setItem('signedUpReferral', 'true')
				props.handleClose()
			} else {
				setIsEmailInvalid(true)
			}
		} catch (error) {
			//console.log('Fatal error:', error)
			datadogReportError(error, 'Referral Modal fatal error')
		}

		setIsLoading(false)
	}

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()
		setIsEmailInvalid(false)
		setEmail(event.target.value)
	}

	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()
		setName(event.target.value)
	}

	const referralExtraOff = PriceManager.convertToCurrency(Number(REFERRAL_DISCOUNT_MAP.split('ultra')[1]), props.currency)
	const amountOff = PriceManager.formatPriceToCurrencyNoDecimal(referralExtraOff, props.currency)
	const referralImage = 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/GE-4307+Referral+Updates.png'

	const title = `Your friend gifted you ${amountOff} off Pod 4 Ultra`
	const subline = `It’s your turn to experience sleep like never before. Unlock your gift today.`

	return (
		<FocusTrapWrapper active={props.modalVisible}>
			<div className={cx('modal_container', props.className, { none: !props.modalVisible })}>
				<div
					className={styles.modal_content_container}
					ref={pageWrapper}
				>
					<button
						aria-label="Close modal button"
						className={styles.close_button}
						onClick={props.handleClose}
					>
						{/* eslint-disable-next-line @next/next/no-img-element */}
						<img
							src="https://eightsleep.imgix.net/Close_Icon_1.png?v=1583943271"
							alt="Close Icon"
						/>
					</button>
					<div className={styles.modal_content}>
						<Img
							src={referralImage}
							alt={'Referral background promo image'}
							className={styles.promoImg}
						/>
						<h3 className={styles.title}>{title}</h3>
						<p>{subline}</p>
						<form
							className={cx(styles.form_container, props.className)}
							onSubmit={handleSubmit}
						>
							<input
								type="text"
								name="name"
								placeholder="Enter your name"
								aria-label="Name Input"
								value={name}
								onChange={handleNameChange}
								// eslint-disable-next-line jsx-a11y/no-autofocus
								autoFocus
							/>

							<input
								type="email"
								name="email"
								placeholder="Enter your email"
								aria-label="Email Input"
								value={email}
								onChange={handleEmailChange}
							/>

							{isEmailInvalid && <p>Email is invalid</p>}

							<Button.Dark
								loading={loading}
								type="submit"
								id={'referral-form-submit'}
							>{`Get ${amountOff} off`}</Button.Dark>
						</form>
					</div>
				</div>
			</div>
		</FocusTrapWrapper>
	)
}

export default Wrapper
