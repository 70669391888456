import React, { FC } from 'react'
import styles from '../MiniModal.module.scss'
import cx from 'classnames'
import { Button } from 'components/WebEv/Buttons'

interface Props {
	email: string
	isInvalid: boolean
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
	cta?: string
	className?: string
	autoComplete?: string
	placeholder?: string
	autoFocus?: boolean
	loading?: boolean
	error?: string
}

const Input: FC<Props> = (props: Props) => (
	<form
		className={cx(styles.form_container, props.className)}
		onSubmit={props.handleSubmit}
	>
		<input
			type="text"
			name="email"
			placeholder={props.placeholder ?? 'Enter your email'}
			aria-label="Email Input"
			value={props.email}
			onChange={props.handleChange}
			autoComplete={props.autoComplete}
			// eslint-disable-next-line jsx-a11y/no-autofocus
			autoFocus={props.autoFocus}
		/>

		{props.isInvalid && <p style={{ marginBottom: '15px' }}>{props.error ?? 'Email is invalid'}</p>}

		<Button.Primary
			id={'modal-email-submit'}
			loading={props.loading}
			size={'large'}
			type="submit"
		>
			{props.cta ?? 'Get Offer'}
		</Button.Primary>
	</form>
)

export default Input
