'use client'

import React, { FC, ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import styles from './Select.module.scss'
import { Button, Menu, MenuItem, Wrapper, WrapperState } from 'react-aria-menubutton'

export interface SelectItem {
	value: string
	text?: string
	menuComponent?: ReactElement
	displayComponent?: ReactElement
}

interface Props {
	isError: boolean
	items: SelectItem[]
	selectedItem?: SelectItem
	placeholder?: ReactNode
	wrapperClassname?: string
	allowEmpty: boolean
	onSelection: (value: string | number | undefined) => void
	onMenuToggle?: (obj: WrapperState) => void
}

const renderSelectButton = (selectedItem?: SelectItem, label?: string) => {
	return selectedItem ? (
		<Button className={styles.select_button}>{selectedItem.displayComponent || selectedItem.menuComponent || selectedItem.text}</Button>
	) : (
		<Button className={cx(styles.select_button, styles.label, 'placeholder')}>{label}</Button>
	)
}

export const Select: FC<Props> = (props) => {
	const [selectedItem, setselectedItem] = useState<SelectItem | undefined>(props.selectedItem)

	useEffect(() => {
		setselectedItem(props.selectedItem)
	}, [props.selectedItem])

	const onSelection = useCallback(
		(value: string, event: React.SyntheticEvent<HTMLElement, Event>) => {
			const item = props.items.find((el) => el.value === value) || props.items[0]
			setselectedItem(item)
			props.onSelection(item.value)
		},
		[props.items, props.onSelection]
	)

	const resetSelection = () => {
		setselectedItem(undefined)
		props.onSelection(undefined)
	}

	const menuItems = props.items.map((item, i) => {
		return (
			<li key={i}>
				<MenuItem
					className={styles.menu_item}
					value={item.value}
				>
					{item.menuComponent || item.text}
				</MenuItem>
			</li>
		)
	})

	return (
		<Wrapper
			className={cx(styles.select_wrapper, props.wrapperClassname, { error: props.isError })}
			onSelection={onSelection}
			onMenuToggle={props.onMenuToggle}
		>
			{renderSelectButton(selectedItem, props.placeholder)}
			{props.allowEmpty && (
				<button
					className={cx(styles.clear_button, selectedItem ? '' : styles.hide_button)}
					onClick={() => resetSelection()}
				>
					&#10006;
				</button>
			)}
			<Menu className={cx(styles.select_menu, 'select_menu')}>
				<ul>{menuItems}</ul>
			</Menu>
		</Wrapper>
	)
}
