import React, { FC, useEffect, useState } from 'react'

import { EMAIL_PROMO_QUERY, SMS_PROMO_QUERY } from 'config/url_promos'
import { amExitIntentEmailCapture, amExitIntentSMSCapture } from 'events/amplitude'
import { setDataLayerVariable } from 'events/ga'
import { trackSubscribeViaEmailCap, trackSubscribeViaSMSCap, trackViewedEmailCap } from 'events/index'
import { sha256 } from 'js-sha256'
import { usePathname, useRouter } from 'next/navigation'
import { CaptureLocation } from 'utils/events'
import { stripPhone, validateEmail, validatePhone } from 'utils/validators'
import Content from './Content'
import { Wrapper } from './Wrapper'
import { useStore } from 'stores/StoreProvider'
import { MiniModalProps } from 'emailcaps/types'

export const ExitIntentModal: FC<MiniModalProps> = (props) => {
	const router = useRouter()
	const pathname = usePathname()
	const [email, setEmail] = useState<string>('')
	const [phone, setPhone] = useState('')
	const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false)
	const [isPhoneInvalid, setIsPhoneInvalid] = useState<boolean>(false)
	const [step, setStep] = useState<'email' | 'phone' | 'complete'>('email')

	const hideEmailCapModal = useStore((state) => state.modal.hideEmailCapModal)

	const abImage = props.settings?.ecapImg || props.settings?.heroSettings?.sources?.slice(-1)[0]?.src || props.imgUrl

	const isVanity = !!props.settings.emailCapSettings

	const modalName = props.settings.saleName ? props.settings.saleName : 'Discount'
	const captureLocation = props.captureLocation
	useEffect(() => {
		if (props.emailCapModalVisible) {
			trackViewedEmailCap(modalName)
		}
	}, [props.emailCapModalVisible, modalName])

	const handleClose = () => {
		const email = localStorage.getItem('email')
		const phone = localStorage.getItem('phone')

		if (phone) {
			router.push(pathname + '?' + SMS_PROMO_QUERY)
		} else if (email) {
			router.push(pathname + '?' + EMAIL_PROMO_QUERY)
		}

		hideEmailCapModal()
	}

	const handleEmailSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const passesValidation: boolean = validateEmail(email)

		const vanityProperties: any = {
			shouldUseVanityFlow: false,
		}

		const properties = {
			email: email,
			emailCapType: modalName,
			location: `${modalName} Modal`,
			captureLocation: CaptureLocation.T1,
			image: props.imgUrl,
			description: props.description,
			...vanityProperties,
			vanity: true,
		}

		if (passesValidation) {
			trackSubscribeViaEmailCap(properties)
			localStorage.setItem('email', email)
			setDataLayerVariable('customer_email', email)
			setDataLayerVariable('hashed_customer_email', sha256(email))
			amExitIntentEmailCapture(email)

			if (props.region === '' || props.region === 'us' || props.region === 'ca') {
				setStep('phone')
			} else {
				setStep('complete')
				setTimeout(handleClose, 10000)
			}
		} else {
			setIsEmailInvalid(true)
		}
	}

	const handlePhoneSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		const strippedPhone = stripPhone(phone)
		const passesValidation = validatePhone(strippedPhone)
		const sphone = '+1' + strippedPhone

		if (passesValidation) {
			trackSubscribeViaSMSCap({
				email: email,
				phone: sphone,
				answer: '',
				location: `${modalName} Modal`,
				captureLocation: captureLocation,
				region: props.region,
			})

			amExitIntentSMSCapture(sphone)
			localStorage.setItem('phone', sphone)
			setDataLayerVariable('hashed_customer_phone', sha256(sphone))
			setStep('complete')
			setTimeout(handleClose, 10000)
		} else {
			setIsPhoneInvalid(true)
		}
	}

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()
		setIsEmailInvalid(false)
		setEmail(event.target.value)
	}

	const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()
		setIsPhoneInvalid(false)
		setPhone(event.target.value)
	}

	const saleName = props.settings.saleName

	return (
		<Wrapper
			handleClose={handleClose}
			emailCapModalVisible={props.emailCapModalVisible}
			preventMouse={props.preventMouse}
		>
			<Content
				title={`Want to unlock your surprise?`}
				description={`Sign up to receive your surprise and start sleeping better today`}
				sms_description={`Your exclusive offer is a click away.`}
				thanksMessage={`We’ve applied your ${props.totalDiscount} off discount to your cart. We’ll also send it over via email.`}
				amount={props.amount}
				subtitle={`Enter your phone number to get texts, and receive up to ${props.totalDiscount} off the Pod.`}
				imgUrl={abImage}
				imgAlt={props.imgAlt}
				step={step}
				email={email}
				phone={phone}
				isEmailInvalid={isEmailInvalid}
				isPhoneInvalid={isPhoneInvalid}
				handleEmailChange={handleEmailChange}
				handlePhoneChange={handlePhoneChange}
				handleEmailSubmit={handleEmailSubmit}
				handlePhoneSubmit={handlePhoneSubmit}
				closeModal={handleClose}
				buttonText={`Get offer`}
				dismissText={props.dismissText}
				partnerName={props.settings.partnerName}
				saleName={saleName}
				eyebrow={'Limited time'}
			/>
		</Wrapper>
	)
}
