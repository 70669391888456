'use client'

import { datadogRum } from '@datadog/browser-rum'
import ErrorBoundary from 'components/basic/ErrorBoundary/ErrorBoundary'
import { GOOGLE_ANALYTICS_ID, GOOGLE_MEASUREMENT_ID } from 'constants/index'
import { FC, PropsWithChildren } from 'react'
import ReactGA from 'react-ga'
import ReactGAV4 from 'react-ga4'
import { EcapWrapper } from './EcapWrapper'
import { LocalizeModalWrapper } from './LocalizeModalWrapper'
import { SupportChatWrapper } from './SupportChatWrapper'
import { useExitIntentTimer } from 'hooks/useExitIntentTimer'
import dynamic from 'next/dynamic'

// lazy load sleep quiz modal using nextjs dynamic import
const SleepQuizModal = dynamic(
	() => import('emailcaps/SleepQuizModal/QuizModal').then((module) => module.SleepQuizModal),
	{
		ssr: false,
	}
)

declare global {
	interface Window {
		initAgentioPixel?: (p: string) => void
		pdst: (p: string) => void
		listabtests: () => void
	}
}

const commitSHA = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ?? ''

datadogRum.init({
	applicationId: 'aa1ef1da-b822-47b1-825f-4f1aa77d758a',
	clientToken: 'pub959da72febe5112786198209bb55046a',
	// `site` refers to the Datadog site parameter of your organization
	// see https://docs.datadoghq.com/getting_started/site/
	site: 'datadoghq.com',
	service: 'eight-web',
	env: process.env.NEXT_PUBLIC_IS_PROD !== undefined ? 'prod' : 'dev',
	version: commitSHA.slice(0, 7),
	sessionSampleRate: 10, //10%
	sessionReplaySampleRate: 1, //1%
	trackUserInteractions: true,
	trackResources: true,
	trackLongTasks: true,
	defaultPrivacyLevel: 'mask-user-input',
})

if (typeof window !== 'undefined') {
	ReactGA.initialize(GOOGLE_ANALYTICS_ID, {
		debug: false,
	})
	ReactGA.plugin.require('ec')
	ReactGA.plugin.require('linker')
	// ReactGA.ga('linker:autoLink', ['rechargeapps.com', 'eightsleep.com'], false, true)

	ReactGAV4.initialize(GOOGLE_MEASUREMENT_ID, {
		gaOptions: { allowLinker: true },
	})
	// ReactGAV4.ga('linker:autoLink', ['rechargeapps.com', 'eightsleep.com'], false, true)
}

const AppWrapper: FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
	const { exitIntentEvent, unpauseExitIntent } = useExitIntentTimer()

	return (
		<div
			onMouseLeave={exitIntentEvent}
			onMouseEnter={unpauseExitIntent}
		>
			<ErrorBoundary>{children}</ErrorBoundary>

			<EcapWrapper />
			<SleepQuizModal />
			<LocalizeModalWrapper />

			<SupportChatWrapper />
		</div>
	)
}

export default AppWrapper
