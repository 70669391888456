/* eslint-disable @next/next/no-img-element */
import React, { FC, useCallback, useState } from 'react'
import styles from './Complete.module.scss'
import { Button } from 'components/WebEv/Buttons'
import { IconMap } from 'components/_const_assets/icons'
import { shuffleArray } from 'components/_utils/arrayUtils'
import cx from 'classnames'
import { Icon, IconName } from 'components/Phantom/Icon'
import { Type } from 'components/Type'

interface Props {
	answerSubmit?: (abbr: QuestionAbbr) => void
	answer?: QuestionAbbr
	hidePill?: boolean
	quizQuestion?: string
	headerText?: string
	subheaderText?: string
	subtitleText?: string
	mysteryEcap?: boolean
	title?: string
	questionClass?: string
	amount?: string
	dismissText?: string
	closeModal?: () => void
	phoneGiven?: boolean
}

interface QuizItem {
	question: string
	description: string
	explanation: string
	abbr: QuestionAbbr
	icon?: string
}

interface QuizItemUpdated {
	question: string
	description: string
	explanation: string
	abbr: QuestionAbbr
	icon?: IconName
}

export enum QuestionAbbr {
	Snore = 'Snore',
	Night_sweats = 'Night_sweats',
	Falling_staying_asleep = 'Falling_staying_asleep',
	Low_energy = 'Low_energy',
	None = 'None',
	Wake_up = 'Wake_up',
	HotCold = 'HotCold',
}

export const quizQuestion = 'What’s your biggest sleep challenge?'

export const quizData: Map<QuestionAbbr, QuizItem> = new Map([
	[
		QuestionAbbr.Night_sweats,
		{
			question: 'I feel too hot at night',
			description: 'We’ve helped thousands of people rid their night sweats once and for all with our award-winning Pod.',
			explanation: 'People who sleep on the Pod experience up to a 69% reduction in heat-related sleep disturbances.',
			abbr: QuestionAbbr.Night_sweats,
			icon: IconMap.temperature,
		},
	],
	[
		QuestionAbbr.Snore,
		{
			question: 'I snore',
			description: 'We’ve helped thousands of snorers get better sleep with our award-winning Pod.',
			explanation: 'People who sleep on the Pod experience up to 32% higher quality sleep.',
			abbr: QuestionAbbr.Snore,
			icon: IconMap.moon,
		},
	],
	[
		QuestionAbbr.Low_energy,
		{
			question: 'I don’t feel rested upon waking',
			description: 'We’ve helped thousands of people improve their energy levels upon waking with the Pod.',
			explanation: 'People who sleep on the Pod experience up to 34% more energy.',
			abbr: QuestionAbbr.Low_energy,
			icon: IconMap.lightning_bolt,
		},
	],
	[
		QuestionAbbr.Falling_staying_asleep,
		{
			question: 'I have a hard time falling or staying asleep',
			description: 'We’ve helped thousands of people fall asleep and stay asleep with our award-winning Pod.',
			explanation: 'People who sleep on the Pod fall asleep up to 44% faster, and experience up to 23% less sleep interruptions.',
			abbr: QuestionAbbr.Falling_staying_asleep,
			icon: IconMap.moon,
		},
	],
	[
		QuestionAbbr.None,
		{
			question: 'I don’t have any sleep concerns',
			description: 'Achieve better sleep with our award-winning Pod, and unlock benefits in health, focus and recovery.',
			explanation: 'People who sleep on the Pod experience up to 34% more deep sleep.',
			abbr: QuestionAbbr.None,
			icon: IconMap.moon,
		},
	],
])

export const quizDataUpdated: Map<QuestionAbbr, QuizItemUpdated> = new Map([
	[
		QuestionAbbr.Night_sweats,
		{
			question: 'I feel too hot at night',
			description: 'The Pod has helped thousands of people rid their night sweats once and for all.',
			explanation: 'People who sleep on the Pod experience up to a 69% reduction in heat-related disturbances.',
			abbr: QuestionAbbr.Night_sweats,
			icon: 'CoolDark',
		},
	],
	[
		QuestionAbbr.Snore,
		{
			question: 'I snore',
			description: 'The Pod has helped thousands of people stop their snoring and get higher quality sleep. ',
			explanation: 'People who sleep on the Pod experience reductions in snoring by up to 45%.',
			abbr: QuestionAbbr.Snore,
			icon: 'SnoringLight',
		},
	],
	[
		QuestionAbbr.Low_energy,
		{
			question: 'I don’t feel rested upon waking',
			description: 'The Pod has helped thousands of people improve their energy levels upon waking. ',
			explanation: 'People who sleep on the Pod experience up to 34% more day time energy.',
			abbr: QuestionAbbr.Low_energy,
			icon: 'ExerciseDark',
		},
	],
	[
		QuestionAbbr.Falling_staying_asleep,
		{
			question: 'I have a hard time falling or staying asleep',
			description: 'The Pod has helped thousands of people fall asleep faster and stay asleep longer.',
			explanation: 'People who sleep on the Pod fall asleep up to 44% faster.',
			abbr: QuestionAbbr.Falling_staying_asleep,
			icon: 'TrendingUpDark',
		},
	],
	[
		QuestionAbbr.None,
		{
			question: 'I don’t have any sleep concerns',
			description: 'The Pod has helped thousands of people improve their sleep and unlock benefits in health, focus and memory.',
			explanation: 'People who sleep on the Pod experience up to 34% more deep sleep.',
			abbr: QuestionAbbr.None,
			icon: 'BedDark',
		},
	],
])

const getShuffledQuestions = () => {
	const quizDataValues = Array.from(quizData.values())
	const withoutNone = quizDataValues.filter((q) => q.abbr !== QuestionAbbr.None)
	const shuffled = shuffleArray(withoutNone)
	shuffled.push(quizDataValues.find((q) => q.abbr === QuestionAbbr.None))
	return shuffled
}

export const Question: FC<Props> = (props) => (
	<div className={styles.complete_wrapper}>
		{props.hidePill ? null : (
			<div className={styles.pill_container}>
				<span className={styles.pill}>Offer applied</span>
			</div>
		)}
		{/* {props.title ? <p className={styles.supertitle}>{props.title}</p> : null} */}
		{props.title ? <p className={styles.supertitle}>{props.headerText}</p> : null}
		{props.headerText && <h3 className={styles.headerText}>{props.headerText}</h3>}
		{props.subheaderText && <p className={styles.subtitle}>{props.subheaderText}</p>}
		<h4 className={props.questionClass}>{props.quizQuestion ?? quizQuestion}</h4>
		{props.subtitleText && <p className={styles.subtitle}>Help us learn more about you for personalized tips.</p>}
		<div className={styles.quiz}>
			{getShuffledQuestions().map((data, index) => (
				<Button.Secondary
					key={`quiz-question-${index}`}
					id={`quiz-question-${index}`}
					className={styles.button}
					onClick={() => props.answerSubmit(data.abbr)}
				>
					{data.question}
				</Button.Secondary>
			))}
		</div>
	</div>
)

export const QuestionWithSubmit: FC<Props> = (props) => {
	const [selectedAnswer, setSelectedAnswer] = useState<QuestionAbbr | undefined>()
	const [questions, setQuestions] = useState(getShuffledQuestions())
	// props.answerSubmit(data.abbr)
	return (
		<div className={cx(styles.complete_wrapper)}>
			{props.hidePill ? null : (
				<div className={styles.pill_container}>
					<span className={styles.pill}>Offer applied</span>
				</div>
			)}
			{/* {props.title ? <p className={styles.supertitle}>{props.title}</p> : null} */}
			{props.headerText && <h3>{props.headerText}</h3>}
			{props.subheaderText && <p className={styles.subtitle}>{props.subheaderText}</p>}
			<h4 className={styles.question}>{props.quizQuestion ?? quizQuestion}</h4>
			{props.subtitleText && <p className={styles.subtitle}>Help us learn more about you for personalized tips.</p>}
			<div className={styles.quiz_wrapper}>
				<div className={styles.quiz}>
					{questions.map((data, index) => (
						<Button.Secondary
							key={`quiz-question-${index}`}
							id={`quiz-question-${index}`}
							className={cx(styles.button, data.abbr === selectedAnswer ? styles.button_selected : '')}
							onClick={() => setSelectedAnswer(data.abbr)}
						>
							{data.question}
						</Button.Secondary>
					))}
				</div>
				<Button.Primary
					id="quiz-submit"
					size={'large'}
					onClick={() => {
						if (selectedAnswer) {
							props.answerSubmit(selectedAnswer)
						}
					}}
				>
					Get offer
				</Button.Primary>
			</div>
		</div>
	)
}

export const CompleteUpdated: FC<Props> = (props) => {
	const quizItem = quizDataUpdated.get(props.answer)
	if (!quizItem) return null
	return (
		<div className={cx(styles.complete_wrapper, styles.new_quiz)}>
			<Type.Headline3 as={'h2'}>Your exclusive offer has been automatically applied</Type.Headline3>

			<Type.Body1 className={styles.subtitle}>{quizItem.description}</Type.Body1>
			<div className={styles.explanation}>
				<div className={styles.explanation_image}>
					<Icon
						color={'black'}
						name={quizItem.icon}
					/>
				</div>
				<div className={styles.explanation_text}>{quizItem.explanation}</div>
			</div>
			<Button.Primary
				id={'ecap-quiz-final-step'}
				size={'large'}
				className={styles.button}
				onClick={props.closeModal}
			>
				Shop now
			</Button.Primary>
		</div>
	)
}

const CLOSE_URLS = ['/product/pod-cover/', '/product/pod4ultra/', '/product/pod4/', '/product/sleep-elixir/']

export const Complete: FC<Props> = (props) => {
	const quizItem = quizData.get(props.answer)

	const getButtonText = useCallback(() => {
		if (props.mysteryEcap) {
			return 'Shop now'
		}

		return 'Redeem offer'
	}, [props.mysteryEcap])

	const getButtonAction = useCallback(() => {
		const pathname = typeof window !== 'undefined' ? window.location.pathname : ''
		if (CLOSE_URLS.some((url) => pathname.includes(url))) {
			return {
				href: undefined,
				onClick: props.closeModal,
			}
		}

		return {
			href: undefined,
			onClick: props.closeModal,
		}
	}, [props.mysteryEcap, props.closeModal])

	const mysteryEcap = props.mysteryEcap ? styles.mystery_ecap : ''
	const { phoneGiven = true } = props
	if (!quizItem) return null

	return (
		<div className={cx(styles.complete_wrapper, mysteryEcap)}>
			{/* {props.title ? <p className={styles.supertitle}>{props.title}</p> : null} */}
			{mysteryEcap ? <h3>Your exclusive email offer has been automatically applied</h3> : <h4>Your exclusive email offer has been automatically applied</h4>}
			<p className={styles.subtitle}>It will also be emailed to you for later use. The Pod has helped thousands of people rid their night sweats once and for all.</p>
			<div className={styles.explanation}>
				<div className={styles.explanation_image}>
					<img
						src={quizItem.icon || IconMap.moon}
						alt="icon"
					/>
				</div>
				<div className={styles.explanation_text}>{quizItem.explanation}</div>
			</div>
			<div className={styles.button_container}>
				<Button.Primary
					id={'ecap-quiz-final-step'}
					className={styles.button}
					{...getButtonAction()}
				>
					{getButtonText()}
				</Button.Primary>
			</div>
		</div>
	)
}
