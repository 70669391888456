import React from 'react'
import { Input } from '../MiniModal/Input'
import styles from './ExitIntentModal.module.scss'
import { Button } from 'components/WebEv/Buttons'
import { Img } from 'components/basic/Img'
import { Type } from 'components/Type'

interface Props {
	title: string
	description: string
	sms_description: string
	thanksMessage?: string
	subtitle?: string
	imgUrl: string
	imgAlt: string
	step: string
	email: string
	phone: string
	isEmailInvalid: boolean
	isPhoneInvalid: boolean
	handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	handlePhoneChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	handleEmailSubmit: (event: React.FormEvent<HTMLFormElement>) => void
	handlePhoneSubmit: (event: React.FormEvent<HTMLFormElement>) => void
	amount?: string
	closeModal: () => void
	buttonText: string
	dismissText: string
	partnerName: string
	saleName: string
	eyebrow: string
}

const Content: React.FC<Props> = (props: Props) => (
	<div className={styles.root}>
		<div className={styles.exit_intent_image}>
			<Img
				src={props.imgUrl}
				alt={props.imgAlt}
				objectFit={'cover'}
				className={styles.img}
			/>
		</div>
		<div className={styles.cap_container}>
			{props.step === 'email' && (
				<>
					<div className={styles.info_container}>
						<Type.Eyebrow className={styles.suptitle}>{props.eyebrow}</Type.Eyebrow>
						<Type.Headline4
							as={'h2'}
							className={styles.promoTitle}
						>
							{props.title}
						</Type.Headline4>
						<Type.Body2 className={styles.promoDescription}>{props.description}</Type.Body2>
					</div>

					<Input
						email={props.email}
						isInvalid={props.isEmailInvalid}
						handleChange={props.handleEmailChange}
						handleSubmit={props.handleEmailSubmit}
						autoComplete="email"
						cta={props.buttonText}
						className={styles.email_input}
						placeholder="Email *"
					/>

					<Button.Empty
						id={'email-cap-dismiss'}
						className={styles.button}
						onClick={props.closeModal}
					>
						{props.dismissText}
					</Button.Empty>

					<p className={styles.disclaimer}>
						By continuing, you accept our privacy policy. Your personal data will be processed so that we can contact you about our products and send you surveys via e-mail. Read our Privacy Policy
						for more information.
					</p>
				</>
			)}
			{props.step === 'phone' && (
				<>
					<div className={styles.info_container}>
						<Type.Eyebrow className={styles.suptitle}>{props.eyebrow}</Type.Eyebrow>
						<Type.Headline4
							as={'h2'}
							className={styles.promoTitle}
						>
							Get texts to unlock savings
						</Type.Headline4>
						<Type.Body2 className={styles.promoDescription}>{props.sms_description}</Type.Body2>
					</div>

					<Input
						email={props.phone}
						isInvalid={props.isPhoneInvalid}
						className={styles.email_input}
						handleChange={props.handlePhoneChange}
						handleSubmit={props.handlePhoneSubmit}
						autoComplete="phone"
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus={true}
						cta={props.buttonText}
						placeholder="Phone number *"
						error="Invalid phone number."
					/>
					<Button.Empty
						id={'sms-cap-dismiss'}
						className={styles.button}
						onClick={props.closeModal}
					>
						{props.dismissText}
					</Button.Empty>
					<p className={styles.disclaimer}>
						By continuing, you accept our privacy policy. Your personal data will be processed so that we can contact you about our products and send you surveys via e-mail. Read our Privacy Policy
						for more information.
					</p>
				</>
			)}

			{props.step === 'complete' && (
				<>
					<div className={styles.info_container}>
						<Type.Eyebrow className={styles.suptitle}>{props.eyebrow}</Type.Eyebrow>
						<Type.Headline4
							as={'h2'}
							className={styles.promoTitle}
						>
							Your offer has been applied
						</Type.Headline4>
						<Type.Body2 className={styles.promoDescription}>
							Thanks for signing up. <br /> <br />
							{props.thanksMessage}
						</Type.Body2>
					</div>
					<div className={styles.button_container}>
						<Button.Primary
							id={'modal-email-submit'}
							className={styles.big_button}
							size={'large'}
							type="submit"
							onClick={props.closeModal}
						>
							Shop now
						</Button.Primary>
					</div>
				</>
			)}
		</div>
	</div>
)

export default Content
