import { RefObject, useEffect } from 'react'

// reusable clickoutside hook meant for modals
// will call the argument handler (modal closing function) on outside click or "ESC" key
export const useClickOutsideModal = (ref: RefObject<HTMLElement | null>, handler: () => void, preventMouse = false): void => {
	useEffect(() => {
		const modalListenerHandler = (event: MouseEvent): void => {
			if (!ref.current) return
			const node = event.target as Node
			if (ref.current.checkVisibility({ opacityProperty: true, visibilityProperty: true }) === false) {
				return
			}
			if (event.type === 'mouseup' && (ref.current.contains(node) || node.nodeName === 'A' || node.nodeName === 'BUTTON')) {
				event.stopPropagation()
				return
			} else if (!preventMouse) {
				handler()
			}
		}

		const keyHandler = (event: KeyboardEvent): void => {
			if (event.key === 'Esc' || event.key === 'Escape') {
				handler()
			}
		}

		if (!preventMouse) {
			document.addEventListener('mouseup', modalListenerHandler)
		}
		document.addEventListener('keyup', keyHandler)

		return () => {
			if (!preventMouse) {
				document.removeEventListener('mouseup', modalListenerHandler)
			}
			document.removeEventListener('keyup', keyHandler)
		}
	}, [ref, handler, preventMouse])
}
