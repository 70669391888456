import React, { useEffect, useRef, useState } from 'react'
import styles from '../MiniModal.module.scss'
import cx from 'classnames'
import FocusTrapWrapper from 'components/FocusTrapWrapper'
import { useClickOutsideModal } from 'hooks/useClickOutsideModal'
import corestyles from 'emailcaps/core/styles.module.scss'

interface Props {
	className?: string
	emailCapModalVisible: boolean
	children: any
	handleClose: () => void
	preventMouse?: boolean
}

const Wrapper: React.FC<Props> = (props: Props) => {
	const [isMounted, setIsMounted] = useState(false) // Prevents the modal from jumping in when it's mounted while visible

	useEffect(() => {
		setIsMounted(true)
	}, [])

	const pageWrapper = useRef(null)
	useClickOutsideModal(pageWrapper, props.handleClose, props.preventMouse)

	return (
		<FocusTrapWrapper active={props.emailCapModalVisible}>
			<div className={cx(corestyles.container, { [corestyles.none]: !props.emailCapModalVisible || !isMounted }, props.className ?? '')}>
				<div
					className={styles.modal_content_container}
					ref={pageWrapper}
				>
					<button
						aria-label="Close modal button"
						className={styles.close_button}
						onClick={props.handleClose}
					>
						<img
							src="https://eightsleep.imgix.net/Close_Icon_1.png?v=1583943271"
							alt="Close Icon"
						/>
					</button>
					<div className={styles.modal_content}>
						<div>{props.children}</div>
					</div>
				</div>
			</div>
		</FocusTrapWrapper>
	)
}

export default Wrapper
