import { getNormalizedURL } from 'components/_utils/urlUtils'
import { datadogReportError } from 'events/datadog'
import { safeError } from 'events/error.utils'
import { useParams, usePathname } from 'next/navigation'
import { useCallback } from 'react'
import { useStore } from 'stores/StoreProvider'
import * as eventNames from 'timerevents/eventNames'
import { getSafeLocale } from 'utils/internationalization'

export const useExitIntentTimer = (): { unpauseExitIntent: () => void; exitIntentEvent: () => void; showExitIntentPopUp: () => void } => {
	const params = useParams()
	const pathname = usePathname()

	const timerEvents = useStore((state) => state.timer.events)
	const emailCapModalVisible = useStore((state) => state.modal.emailCapModalVisible)
	const showEmailCapModalShortcut = useStore((state) => state.modal.showEmailCapModalShortcut)
	const toggleExitIntenEcap = useStore((state) => state.modal.toggleExitIntenEcap)

	const unpauseExitIntent = useCallback(() => {
		sessionStorage.setItem('pause_exit_intent', '0')
	}, [])

	const showExitIntentPopUp = useCallback(() => {
		const locale = getSafeLocale(params as Record<string, string | string[]>)
		const normalizedURL = getNormalizedURL(locale, pathname, params)
		const displayPopUp =
			typeof window !== 'undefined' &&
			!emailCapModalVisible &&
			showEmailCapModalShortcut &&
			localStorage.getItem('email') === null &&
			sessionStorage.getItem(eventNames.emailCapModalTriggerDone) !== null &&
			sessionStorage.getItem(eventNames.emailCapShortcutButtonTriggerDone) === null &&
			normalizedURL.startsWith('/product') &&
			!normalizedURL.includes('/sleep-elixir')
		if (displayPopUp) {
			toggleExitIntenEcap(true)
		}
	}, [pathname, params, emailCapModalVisible, showEmailCapModalShortcut, toggleExitIntenEcap])

	const exitIntentEvent = useCallback(() => {
		if (window.sessionStorage.getItem('block-exit-intent') === 'true') {
			return
		}

		const exitIntentEventBlocked = sessionStorage.getItem('pause_exit_intent') === '1'
		timerEvents.InactivityModalEvent?.resetTimer(showExitIntentPopUp, 500)
		if (exitIntentEventBlocked) {
			return
		}
		try {
			if (timerEvents.assistedSaleModalEvent?.canBeTriggered ?? false) {
				timerEvents.assistedSaleModalEvent?.action()
			}
		} catch (e: unknown) {
			datadogReportError(safeError(e), 'Exit intent')
		}
	}, [showExitIntentPopUp, timerEvents])

	return { unpauseExitIntent, exitIntentEvent, showExitIntentPopUp }
}
