/* eslint-disable @next/next/no-img-element */
import React, { useState } from 'react'
import cx from 'classnames'
import corestyles from '../core/styles.module.scss'
import styles from './QuizWithImage.module.scss'
import { Img } from 'components/basic/Img'
import FocusTrapWrapper from 'components/FocusTrapWrapper'
import { useClickOutsideModal } from 'hooks/useClickOutsideModal'

interface WrapperBProps {
	pageWrapper: any
	handleClose: any
	emailCapModalVisible: any
	children: any
	settings: any
	desktopImg?: string
	mobileImg?: string
}

export const ModalWrapper: React.FC<WrapperBProps> = (props) => {
	const { pageWrapper, handleClose, emailCapModalVisible, children, settings } = props
	useClickOutsideModal(pageWrapper, handleClose, settings.isEULegacy || settings.isUK)

	const [focusCount, setFocusCount] = useState(0)

	const handleFocus = () => {
		setFocusCount((prev) => prev + 1)
	}

	return (
		<FocusTrapWrapper active={emailCapModalVisible}>
			<div className={cx(corestyles.container, { [corestyles.none]: !emailCapModalVisible })}>
				<div
					className={cx(styles.content_new, styles.full_screen)}
					ref={pageWrapper}
				>
					<button
						type="button"
						aria-label="Close modal button"
						onClick={handleClose}
						onFocus={handleFocus}
						className={`${styles.close_button} ${focusCount <= 1 ? 'no-focus-outline' : ''}`}
					>
						<img
							aria-label={'Close'}
							src="https://eightsleep.imgix.net/close_d716c4c1-e156-409d-93c2-92907327e991.svg?v=1679385254"
							alt="Close popup icon"
						/>
					</button>
					<Img
						src={props.desktopImg}
						sources={[
							{ mediaQuery: '(min-width: 1023px)', src: props.desktopImg, dprHeight: 2000 },
							{ mediaQuery: '(max-width: 1023px)', src: props.mobileImg, dprHeight: 2000 },
						]}
						className={cx(styles.ecap_image)}
						alt="Eight Sleep branding message"
						objectFit={'cover'}
						doNotCompress
					/>
					{children}
				</div>
			</div>
		</FocusTrapWrapper>
	)
}

export default ModalWrapper
