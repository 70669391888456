import React, { ReactNode, useEffect, useState } from 'react'
import FocusTrap from 'focus-trap-react'

export interface FocusTrapWrapperProps {
	active: boolean
	children?: ReactNode
}

const elementVisible = (elem: HTMLElement): boolean => {
	const style = window.getComputedStyle(elem)
	return (
		style.width !== '0px' &&
		style.height !== '0px' &&
		style.opacity !== '0' &&
		style.display !== 'none' &&
		style.visibility !== 'hidden'
	)
}

const FocusTrapWrapper: React.FC<FocusTrapWrapperProps> = (props) => {
	const [cookieModalActive, setcookieModalActive] = useState(false)

	useEffect(() => {
		let counter = 1
		const timerCookieCheck = setInterval(() => {
			if (typeof window !== 'undefined') {
				const el = document.getElementById('onetrust-banner-sdk')
				if (counter > 10) {
					clearInterval(timerCookieCheck)
				} else {
					setcookieModalActive(el !== null && elementVisible(el))
					counter++
				}
			}
		}, 1000)

		return () => {
			clearInterval(timerCookieCheck)
		}
	}, [])

	return <FocusTrap active={props.active && !cookieModalActive}>{props.children}</FocusTrap>
}

export default FocusTrapWrapper
