import Script from 'next/script'
import { Img } from 'components/basic/Img'
import { Button } from 'components/Phantom/Button'
import { FC } from 'react'

declare global {
	interface Window {
		Kustomer?: any
		duet?: any
	}
}

export interface ISupportChat {}

const SupportChat: FC = () => {
	return (
		<>
			<Button.Empty
				id={'decagon-chat-bubble'}
				ariaLabel={'Open chat'}
				onClick={() => {
					if (window.duet && window.duet.isReady()) {
						window.duet.open()
					}
				}}
			>
				<Img
					src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/chat_icon_new.png'}
					alt={'Chat with Eight Sleep'}
				/>
			</Button.Empty>
			<Script
				src="https://decagon.ai/loaders/eight_sleep.js"
				onLoad={() => {
					window.duet?.hide()
					document.querySelector('iframe[src="https://decagon.ai/demo/eight_sleep"]')?.setAttribute('title', 'Decagon chat')
				}}
			/>
		</>
	)
}

export default SupportChat
