import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation'

export const useSafeSearchParams = (): ReadonlyURLSearchParams => {
	const searchParams = useSearchParams()

	// ReadonlyURLSearchParams is just an extension of URLSearchParams
	// but with its mutators voided out. So we can safely cast to it.
	// eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
	return searchParams ?? (new URLSearchParams() as ReadonlyURLSearchParams)
}
