import { Button } from 'components/WebEv/Buttons'
import cx from 'classnames'
import { Icon } from 'components/Phantom/Icon'
import styles from './FloatingButton.module.scss'
import { Type } from 'components/Type'
import { trackEmailModalDismissFloatingButtonPress, trackEmailModalFloatingButtonPress } from 'events/index'
import { useStore } from 'stores/StoreProvider'

interface FloatingButtonProps {
	isPDP: boolean
	isVisible: boolean
}

export const FloatingButton: React.FC<FloatingButtonProps> = (props) => {
	const { isPDP, isVisible } = props
	const forcedisplayEmailCapModal = useStore((state) => state.modal.forcedisplayEmailCapModal)
	const preventDisplayEmailCapModalShortcut = useStore((state) => state.modal.preventDisplayEmailCapModalShortcut)

	return (
		<div
			className={cx(styles.shortcut, { [styles.pdp_shortcut]: isPDP, [styles.visible]: isVisible })}
			id={'floating-modal-container'}
		>
			<Button.Empty
				id={'floating-modal'}
				size={'compact'}
				onClick={() => {
					trackEmailModalFloatingButtonPress()
					forcedisplayEmailCapModal()
				}}
			>
				<Type.Eyebrow>Unlock a surprise</Type.Eyebrow>
			</Button.Empty>
			<Button.Empty
				id={'close-shortcut'}
				onClick={() => {
					trackEmailModalDismissFloatingButtonPress()
					preventDisplayEmailCapModalShortcut()
				}}
			>
				<Icon
					name={'CloseLight'}
					color={'white'}
					size={24}
				/>
			</Button.Empty>
		</div>
	)
}
