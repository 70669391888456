'use client'

import { ClientOnly } from 'components/ClientOnly'
import SupportChat from 'components/SupportChat/SupportChat'
import { useEffect, useState } from 'react'

export const SupportChatWrapper = () => {
	const [isAutomated, setIsAutomated] = useState(false)
	const [canonicalUrl, setCanonicalUrl] = useState('')

	useEffect(() => {
		setIsAutomated(sessionStorage.getItem('automated') === 'true' || window.location.search.includes('automated=true'))
		setCanonicalUrl(window.location.pathname)
	}, [])

	if (isAutomated || canonicalUrl.startsWith('/membership-details') || canonicalUrl.startsWith('/eoy')) {
		return null
	}

	return (
		<ClientOnly>
			<SupportChat />
		</ClientOnly>
	)
}
