/* eslint-disable @next/next/no-img-element */
'use client'

import { getNormalizedURL } from 'components/_utils/urlUtils'
import { UUID_COOKIE_NAME } from 'middleware'
import { useParams, usePathname, useSearchParams } from 'next/navigation'
import Script from 'next/script'
import { FC, useEffect, useState } from 'react'
import { useIsEULegacy, useIsUK } from 'stores/settings'
import { useStore } from 'stores/StoreProvider'
import { getSafeLocale } from 'utils/internationalization'
import { loadKlaviyoObject } from 'utils/klaviyo'

/**
 * Taken directly from pages/_app.tsx. Do not accept this component as gospel,
 * it's part of the migration process.
 */
export const ScriptLoader: FC = () => {
	const [automated, setAutomated] = useState(false)
	const [renderCookieConsent, setRenderCookieConsent] = useState(false)
	const pathname = usePathname() ?? ''

	useEffect(() => {
		const deviceId =
			document.cookie
				.split('; ')
				.find((row) => row.startsWith(UUID_COOKIE_NAME))
				?.split('=')[1] ?? ''
		window.podscribe?.('view', { device_id: deviceId })
	}, [pathname])

	const params = useParams()
	const searchParams = useSearchParams()

	const queryRegion = getSafeLocale(params)
	const normalizedPath = getNormalizedURL(queryRegion, pathname, params ?? undefined)
	const eoyURL = normalizedPath.includes('eoy20')
	const verifyPassTesting = searchParams?.get('verifyPass') !== null && searchParams?.get('verifyPass') !== undefined
	const loadAgentioScript = ['/mattjones', '/calumjohnson', '/karuesell', '/allieo', '/robdahm', '/emeliahartford', '/trevorbauer'].some((it) => normalizedPath.includes(it))

	useEffect(() => {
		const interval = setInterval(() => {
			if (sessionStorage.getItem('automated') === 'true' || window.location.search.includes('automated=true')) {
				document.body.classList.add('hide_one_trust')
				setAutomated(true)
			}
		}, 5000)

		return () => {
			clearInterval(interval)
		}
	}, [])

	const isUK = useIsUK()
	const isEULegacy = useIsEULegacy()
	const currentRegion = useStore((state) => state.settings.currentRegion)
	const setLocalizeReady = useStore((state) => state.settings.setLocalizeReady)

	useEffect(() => {
		setRenderCookieConsent(
			typeof window !== 'undefined' &&
				location.hostname !== 'localhost' &&
				!automated &&
				!location.search.includes('member=true') &&
				!location.search.includes('automated=true') &&
				(isUK || isEULegacy) &&
				!window.ReactNativeWebView
		)
	}, [automated, isUK, isEULegacy, params, pathname])

	return (
		<>
			{(queryRegion === 'eu' || (typeof window !== 'undefined' && window.location.hostname.endsWith('.com.mx')) || currentRegion === 'mx') && !eoyURL && (
				<>
					<Script
						strategy="afterInteractive"
						src="https://global.localizecdn.com/localize.js"
						onLoad={() => {
							function localizePolyfill(a: Window & typeof globalThis): void {
								if (!a.Localize) {
									a.Localize = {} as unknown as Window['Localize']
									for (
										let e = [
												'translate',
												'untranslate',
												'phrase',
												'initialize',
												'translatePage',
												'setLanguage',
												'getLanguage',
												'getSourceLanguage',
												'detectLanguage',
												'getAvailableLanguages',
												'untranslatePage',
												'bootstrap',
												'prefetch',
												'on',
												'off',
												'hideWidget',
												'showWidget',
											],
											t = 0;
										t < e.length;
										t++
									) {
										const localize = a.Localize
										if (localize !== undefined) {
											localize[e[t]] = function () {
												// eslint-disable-next-line no-console
												console.error('Localize is not defined')
											}
										}
									}
								}
							}
							localizePolyfill(window)
							window.Localize?.initialize({ key: '5934xTWw07jZp', rememberLanguage: true, disableWidget: true, autoApprove: true, saveNewPhrases: process.env.IS_PROD === 'true' ? false : true })
							setLocalizeReady()
							window.Localize?.on('setLanguage', function () {
								setLocalizeReady()
							})
						}}
					/>
				</>
			)}

			{/* <!-- OneTrust Cookies Consent Notice start for eightsleep.com --> */}
			{renderCookieConsent ? (
				<>
					<Script
						strategy="lazyOnload"
						src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
						data-domain-script="efecf6c5-b77e-46fd-9467-8694ffacb5bf"
						id="cookie-consent-script"
					/>
					<Script
						strategy="lazyOnload"
						id="optanon-script"
						dangerouslySetInnerHTML={{ __html: `function OptanonWrapper() { }` }}
					/>
				</>
			) : (
				<></>
			)}

			{!eoyURL && (
				<Script
					strategy="afterInteractive"
					src="https://static.shopmy.us/Affiliates/sms_aff_clicktrack.js"
				/>
			)}

			{!eoyURL && (
				<Script
					id="klaviyo-script"
					src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=QtJCHb"
					strategy="afterInteractive"
					onLoad={loadKlaviyoObject}
				/>
			)}

			{!eoyURL && (
				<Script
					id={'maze-script'}
					dangerouslySetInnerHTML={{
						__html: `(function(m, a, z, e) {var s, t;try {t = m.sessionStorage.getItem("maze-us");} catch (err) {}if (!t) {t = new Date().getTime();try {m.sessionStorage.setItem("maze-us", t);} catch (err) {}} s = a.createElement("script"); s.src = z + "?apiKey=" + e;s.async = true;a.getElementsByTagName("head")[0].appendChild(s);m.mazeUniversalSnippetApiKey = e;})(window, document, "https://snippet.maze.co/maze-universal-loader.js", "d873ea53-673f-4375-ba14-ee832e988bdf");`,
					}}
				/>
			)}

			<Script
				id="friendbuy-script"
				strategy="lazyOnload"
				dangerouslySetInnerHTML={{
					__html: `window["friendbuyAPI"] = friendbuyAPI = window["friendbuyAPI"] || [];
						// registers your merchant using your merchant ID found in the
						// retailer app https://retailer.friendbuy.io/settings/general
						friendbuyAPI.merchantId = "c2ef4d8f-87a3-4388-8da9-ff2d9ddfe70b";
						friendbuyAPI.push(["merchant", friendbuyAPI.merchantId]);

						// load the merchant SDK and your campaigns
						(function(f, r, n, d, b, u, y) {
							while ((u = n.shift())) {
							(b = f.createElement(r)), (y = f.getElementsByTagName(r)[0]);
							b.async = 1;
							b.src = u;
							y.parentNode.insertBefore(b, y);
							}
						})(document, "script", [
							"https://static.fbot.me/friendbuy.js",
							"https://campaign.fbot.me/" + friendbuyAPI.merchantId + "/campaigns.js",
						]);`,
				}}
			/>

			{process.env.NODE_ENV !== 'development' ? (
				<Script
					strategy="lazyOnload"
					id="gtm-script"
					dangerouslySetInnerHTML={{
						__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
								new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
								j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
								'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
								})(window,document,'script','dataLayer','GTM-KR7VQ5S');`,
					}}
				/>
			) : (
				<></>
			)}

			{process.env.NODE_ENV !== 'development' && loadAgentioScript ? (
				<Script
					strategy="afterInteractive"
					id="agentio-script"
					src="https://static.agentio.com/agentio-pixel.js"
					onLoad={() => {
						window.initAgentioPixel?.('eight_sleep_pixel')
					}}
				/>
			) : (
				<></>
			)}

			{verifyPassTesting && (
				<Script
					src="https://cdn.verifypass.com/seller/launcher.js"
					strategy="lazyOnload"
				></Script>
			)}

			<Script
				strategy="lazyOnload"
				src="https://eightsleep.imgix.net/browser-detect_26cf8eb8-0c2c-4329-a91c-81b6f2cd6572.js?331315"
			/>

			<Script
				strategy="lazyOnload"
				id="pinterest-tracking-script"
				dangerouslySetInnerHTML={{
					__html: `!function(e){if(!window.pintrk){window.pintrk = function () {
					window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
					n=window.pintrk;n.queue=[],n.version="3.0";var
					t=document.createElement("script");t.async=!0,t.src=e;var
					r=document.getElementsByTagName("script")[0];
					r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
					pintrk('load', '2612431567899');
					pintrk('page');
					`,
				}}
			/>

			<noscript>
				<img
					height="1"
					width="1"
					style={{ display: 'none' }}
					alt=""
					role="none"
					src={`https://ct.pinterest.com/v3/?event=init&tid=2612431567899&noscript=1`}
				/>
			</noscript>

			<Script
				strategy="afterInteractive"
				id="podscribe-tracking-script"
				dangerouslySetInnerHTML={{
					__html: `
					(function (w, d) {
						var id = 'podscribe-capture',
						n = 'script';
						var e = d.createElement(n);
						e.id = id;
						e.async = true;
						e.src = 'https://d34r8q7sht0t9k.cloudfront.net/tag.js';
						var s = d.getElementsByTagName(n)[0];
						s.parentNode.insertBefore(e, s);
						e.addEventListener('load', function() {
							w.podscribe('init', { user_id: '46ff822a-e5c3-4892-8fe9-9a12ec338b93', advertiser: 'eightsleep' });
						})
					})(window, document); 
				`,
				}}
			/>
		</>
	)
}
