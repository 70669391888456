export interface ModalImages {
	desktopImg: string
	mobileImg: string
}

export const DEFAULT_MODAL_IMAGES = {
	desktopImg: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/exit_intent_jan_2025.jpeg',
	mobileImg: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/exit_intent_jan_2025.jpeg',
}

export const YOUTUBE_MODAL_IMAGES = {
	shervinshares: {
		desktopImg: 'https://eightsleep.imgix.net/shervin_ecap_desktop.jpg?v=1687441520',
		mobileImg: 'https://eightsleep.imgix.net/shervin_ecap_mobile.jpg?v=1687441520',
	},
	meetpod3: {
		desktopImg: 'https://eightsleep.imgix.net/ytmeetpod3_ecap_desktop.jpg?v=1687441520',
		mobileImg: 'https://eightsleep.imgix.net/ytmeetpod3_ecap_mobile.jpg?v=1687441520',
	},
	sleepElixir: {
		desktopImg: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/sleep-elixir-ecap-desktop.png',
		mobileImg: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/sleep-elixir-ecap-mobile.png',
	},
}

export const PARTNER_MODAL_IMAGES = {
	redGerard: {
		desktopImg: 'https://eightsleep.imgix.net/BLATT--Red-453A6829.jpg?v=1670879192',
		mobileImg: 'https://eightsleep.imgix.net/BLATT--Red-453A6829.jpg?v=1670879192',
	},
	strava: {
		desktopImg: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/strava/strava-ecap.jpg',
		mobileImg: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/strava/strava-ecap.jpg',
	},
	efProcycling: {
		desktopImg: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/ef-ecap-new-logo-2025.jpg',
		mobileImg: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/ef-ecap-new-logo-2025.jpg',
	},
}
