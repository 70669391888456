import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/AppWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientSideEffects"] */ "/vercel/path0/app/ClientSideEffects.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScriptLoader"] */ "/vercel/path0/app/ScriptLoader.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/basic/Img/Img.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../styles/fonts/NeueMontreal/PPNeueMontreal-Variable.woff2\",\"weight\":\"200 800\",\"variable\":\"--neue-montreal\"}],\"variableName\":\"NeueMontreal\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/animate.css/animate.css");
;
import(/* webpackMode: "eager", webpackExports: ["StoreProvider"] */ "/vercel/path0/stores/StoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/dark.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/global.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/grey.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/basic/Img/Img.module.scss");
