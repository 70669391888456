import { useEffect } from 'react'
import { Waypoint } from 'react-waypoint'
import { useStore } from 'stores/StoreProvider'
import styles from './styles.module.scss'
export interface PrimaryEmailCapWaypointProps {
	top: string
	delay?: number
}

const RESTRICTED_PATHNAMES = ['/product/pod4', '/product/pod4ultra']
const canRunTimer = (pathname: string) => {
	for (const restrictedPathname of RESTRICTED_PATHNAMES) {
		if (pathname.includes(restrictedPathname)) {
			return false
		}
	}
	return true
}

export const PrimaryEmailCapWaypoint = (props: PrimaryEmailCapWaypointProps) => {
	const { top, delay = 3 } = props
	const ecapRunTrigger = useStore((state) => state.modal.ecapRunTrigger)

	useEffect(() => {
		const secondsToTrigger = delay
		const timer = setTimeout(() => {
			if (canRunTimer(window.location.pathname)) {
				ecapRunTrigger()
			}
		}, secondsToTrigger * 1000)

		return () => clearTimeout(timer)
	}, [delay, ecapRunTrigger])

	return (
		<div
			className={styles.waypoint}
			style={{ top }}
		>
			<Waypoint
				onEnter={() => {
					ecapRunTrigger()
				}}
				scrollableAncestor={'window'}
			/>
		</div>
	)
}

export default PrimaryEmailCapWaypoint
