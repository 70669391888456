'use client'

/* eslint-disable @next/next/no-img-element */
import { feb27EcapAB } from 'ab_testing'
import { useScrollThreshold } from 'components/_hooks/useScrollThreshold'
import { useUTMParams } from 'components/_hooks/useUTMParams'
import { partnerVanities } from 'config/partnerVanities'
import { SiteSettings } from 'config/types'
import { YOUTUBE_UTM_CONTENT } from 'config/url_promos'
import useABTestVariant from 'hooks/useABTestVariant'
import { useSafePathname } from 'hooks/useSafePathname'
import { useSafeSearchParams } from 'hooks/useSafeSearchParams'
import { PriceManager } from 'prices'
import { Currency } from 'prices/types'
import { FC, useEffect, useState } from 'react'
import * as eventNames from 'stores/modal/modal.constants'
import { useCurrency, usePod4UltraPrices } from 'stores/prices'
import { useCurrentRegion } from 'stores/settings'
import { useStore } from 'stores/StoreProvider'
import { EMAIL_SMS_DISCOUNT_AMOUNT, EMAIL_SMS_DISCOUNT_CODE, MEMBERS_DISCOUNT_CODE, REFERRAL_COUPON } from 'stripe_lib/discounts'
import { CaptureLocation } from 'utils/events'
import { regions, RegionShort } from 'utils/internationalization'
import { ChronotypeQuiz } from './ChronotypeQuiz/ChronotypeQuiz'
import { ExitIntentModal } from './ExitIntentModal/ExitIntentModal'
import { FloatingButton } from './FloatingButton'
import { MiniModal } from './MiniModal'
import { DEFAULT_MODAL_IMAGES, ModalImages, YOUTUBE_MODAL_IMAGES } from './QuizWithImage/modalImages'
import { QuizWithImage } from './QuizWithImage/QuizWithImage'
import ReferralModal from './ReferralModal/ReferralModal'
import { MiniModalProps } from './types'

export const isPathInExcludedPages = (path: string): boolean => {
	for (const region of regions) {
		path = path.replace(`/${region}/`, '/')
	}

	if (!path.endsWith('/')) {
		path = path + '/'
	}

	for (const url of EXCLUDED_PAGES.urls) {
		if (path === url) {
			return true
		}
	}

	return false
}

export const EXCLUDED_PAGES = {
	urls: [
		'/checkout/contact/',
		'/checkout/payment/',
		'/miami/',
		'/referral/',
		'/costco-shows/',
		'/international/',
		'/member-accessories/',
		'/manuals/',
		'/accessibility/',
		'/return-policy/',
		'/warranty/',
		'/press/',
		'/product/premium-mattress/',
		'/vipaccess/',
		'/sleep-coaching-onboarding/',
		'/sleep-coaching/',
		'/travel/',
		'/hosts/',
		'/brooke/',
		'/member-referrals/',
		'/hubermanvip/',
		'/referred/',
		'/cart/',
		'/summeronice/',
		'/equinoxspa/',
		'/apl/',
		'/hyperice/',
		'/levels/',
		'/referral-terms-conditions/',
		'/finance/',
		'/future/',
		'/future',
		'/higherdose/',
		'/healf/',
		'/lifetime/',
		'/paceline/',
		'/strava/april/',
		'/strava/summerofchampions/',
		'/dreamstack/',
		'/strava/risefor2025/',
		'/login/',
		'/truemed/',
		'/giveawayaceyoursleep/',
		'/ultimategiveaway/',
		'/ultimatehuman/',
		'/codiegiveaway/',
		'/sa/',
		'/miamigrandpadel/',
	],
	queryParams: ['utm_source=hospitality_sleep_report', 'member-accessories=1', 'utm_source=klaviyo', 'utm_source=app', 'member=pod1', 'campaign=levels', 'campaign=future'],
}

const shouldHideModal = (pathname: string, searchParams: URLSearchParams, settings: SiteSettings): boolean => {
	const path = pathname.toLowerCase()
	const queryParams = searchParams.toString().toLowerCase()

	if (path === '/referral/') {
		return true
	}

	if (path.includes('/thank_you/') || path.includes('/checkout/')) {
		return true
	}

	if (settings.promoCode !== undefined) {
		if (settings.promoCode.toLowerCase() === EMAIL_SMS_DISCOUNT_CODE.toLowerCase()) {
			return true
		}

		if (settings.promoCode.endsWith('50')) {
			return true
		}

		if (settings.promoCode.toUpperCase() === MEMBERS_DISCOUNT_CODE) {
			return true
		}
	}

	if (settings.promoCode !== REFERRAL_COUPON && (isPathInExcludedPages(path) || Boolean(EXCLUDED_PAGES.queryParams.find((it) => queryParams.includes(it))))) {
		return true
	}

	return false
}

const getModalData = ({
	isVanity,
	showExitIntent,
	settings,
	modalImages,
	amount,
	isUSorCA,
	currency,
	totalAmount,
	region,
	pathname,
	emailCapModalVisible,
}: {
	isVanity: boolean
	showExitIntent: boolean
	settings: SiteSettings
	modalImages: ModalImages
	amount: number
	isUSorCA: boolean
	currency: Currency
	totalAmount: number
	region: RegionShort
	pathname: string
	emailCapModalVisible: boolean
}): MiniModalProps => {
	const defaultConfig = {
		subtitle: settings.saleName ?? '',
		description: 'Sign up to kick off your sleep fitness journey with an exclusive offer.',
		imgUrl: modalImages.desktopImg,
		imgAlt: '',
		buttonText: 'Join the Club',
		dismissText: "I don't like good sleep",
	}

	const formattedAmount = PriceManager.formatPriceToCurrencyNoDecimal(amount, currency)

	const baseModalData: MiniModalProps = {
		...defaultConfig,
		title: 'Almost there',
		captureLocation: CaptureLocation.MODAL,
		emailCapModalVisible,
		settings: settings,
		preventMouse: !isUSorCA,
		currency: currency,
		amount: formattedAmount,
		desktopImg: modalImages.desktopImg,
		mobileImg: modalImages.mobileImg,
		discountCode: EMAIL_SMS_DISCOUNT_CODE.toLowerCase(),
		totalDiscount: PriceManager.formatPriceToCurrencyNoDecimal(totalAmount, currency),
		region: region,
	}

	if (showExitIntent) {
		return {
			...baseModalData,
			captureLocation: CaptureLocation.MODAL,
			title: 'Want to unlock your surprise?',
			dismissText: "I don't like good sleep",
			imgUrl: DEFAULT_MODAL_IMAGES.desktopImg,
			imgAlt: 'Exit Intent caption',
			settings: {
				...baseModalData.settings,
			},
		}
	}

	// Check for partner modals
	const partnerVanity = Object.values(partnerVanities).find((config) => config.pathMatch(pathname))
	if (partnerVanity) {
		return {
			...baseModalData,
			captureLocation: CaptureLocation.MODAL,
			title: partnerVanity.emailCapSettings.title || (partnerVanity.getTitleWithAmount ? partnerVanity.getTitleWithAmount(formattedAmount) : ''),
			subtitle: partnerVanity.emailCapSettings.subtitle ?? '',
			description: partnerVanity.emailCapSettings.description ?? '',
			imgUrl: partnerVanity.emailCapSettings.imageSrc,
			imgAlt: partnerVanity.emailCapSettings.imageAlt,
			settings: {
				...baseModalData.settings,
				partnerName: partnerVanity.partnerName,
				saleName: partnerVanity.saleName,
			},
		}
	}

	if (isVanity) {
		return {
			...baseModalData,
			captureLocation: CaptureLocation.MODAL,
			title: null,
			amount: null,
			subtitle: '',
			description: null,
			imgUrl: settings.emailCapSettings?.imageSrc ?? defaultConfig.imgUrl,
			imgAlt: settings.emailCapSettings?.imageAlt ?? defaultConfig.imgAlt,
		}
	}

	return baseModalData
}

const getModalImages = ({
	isSleepElixirPDP,
	utmContent,
	utmMedium,
	utmSource,
}: {
	isSleepElixirPDP: boolean
	utmContent: string | null
	utmMedium: string | null
	utmSource: string | null
}): ModalImages => {
	if (isSleepElixirPDP) {
		return YOUTUBE_MODAL_IMAGES.sleepElixir
	}

	if (utmContent === YOUTUBE_UTM_CONTENT.ytshervinshares || (utmMedium === 'cpm' && utmSource === 'facebook')) {
		return YOUTUBE_MODAL_IMAGES.shervinshares
	}

	if (
		(utmContent !== null && [YOUTUBE_UTM_CONTENT.ytmeetpod3, YOUTUBE_UTM_CONTENT.ytugcsleepfitness, YOUTUBE_UTM_CONTENT.ytwakeupenergized].includes(utmContent)) ||
		(utmMedium === 'cpm' && utmSource === 'facebook')
	) {
		return YOUTUBE_MODAL_IMAGES.meetpod3
	}

	return DEFAULT_MODAL_IMAGES
}

export const EmailCapContainer: FC = () => {
	const currency = useCurrency()
	const key = 'emailCapContainer' + Date.now().toString()
	const [referralModalVisible, setReferralModalVisible] = useState(true)

	const pathname = useSafePathname()
	const searchParams = useSafeSearchParams()
	const { utmMedium, utmSource, utmContent, utmCampaign } = useUTMParams()
	const heroWiped = useScrollThreshold(400)

	const forceDisplayEmailCapModalShortcut = useStore((state) => state.modal.forceDisplayEmailCapModalShortcut)
	const emailCapModalVisible = useStore((state) => state.modal.emailCapModalVisible)
	const showEmailCapModalShortcut = useStore((state) => state.modal.showEmailCapModalShortcut)
	const showExitIntent = useStore((state) => state.modal.showExitIntent)
	const hideEmailCapModal = useStore((state) => state.modal.hideEmailCapModal)

	useEffect(() => {
		// Check if the email cap modal has been triggered but the shortcut button hasn't been shown yet
		const triggerDone = sessionStorage.getItem(eventNames.emailCapModalTriggerDone)
		const shortcutDone = sessionStorage.getItem(eventNames.emailCapShortcutButtonTriggerDone)

		// Only show the shortcut if the modal has been closed (not showing) and the shortcut hasn't been shown yet
		if (triggerDone !== null && shortcutDone === null && !emailCapModalVisible) {
			forceDisplayEmailCapModalShortcut()
		}
	}, [forceDisplayEmailCapModalShortcut, emailCapModalVisible])

	const settings = useStore((state) => state.settings.settings)

	// We have vanities and partner vanities, which both lead to rendering the Vanity Modal
	const partnerVanity = Object.values(partnerVanities).find((config) => config.pathMatch(pathname))
	const isVanity = !!settings.emailCapSettings || !!partnerVanity

	const isSleepElixirPDP = pathname.includes('/sleep-elixir')
	const currentRegion = useCurrentRegion()
	const isUSorCA = currentRegion === '' || currentRegion === 'ca'

	const getUpToDiscountNumber = usePod4UltraPrices().discountNumber
	const amount = PriceManager.convertToCurrency(EMAIL_SMS_DISCOUNT_AMOUNT, PriceManager.getRegionCurrency(currentRegion))
	const totalAmount = isVanity ? getUpToDiscountNumber : getUpToDiscountNumber + amount

	const isVariantB = useABTestVariant(feb27EcapAB, 1)
	const ecapVariant = isVariantB ? 1 : 0

	const isLocalizeModalVisible = useStore((state) => state.modal.localizeModalVisible)
	if (shouldHideModal(pathname, searchParams, settings) || isLocalizeModalVisible) {
		hideEmailCapModal()
		return null
	}

	const modalImages = getModalImages({
		isSleepElixirPDP,
		utmContent,
		utmMedium,
		utmSource,
	})

	const modalData = getModalData({
		isVanity,
		showExitIntent,
		settings,
		modalImages,
		amount,
		isUSorCA,
		currency,
		totalAmount,
		region: currentRegion,
		pathname,
		emailCapModalVisible,
	})

	const showReferralEcap = pathname.includes('/pod-cover') && (utmSource === 'friendbuy' || utmCampaign === 'friendbuy' || settings.promoCode === REFERRAL_COUPON)

	const signedUpReferral = typeof window !== 'undefined' ? settings.promoCode === REFERRAL_COUPON && sessionStorage.getItem('signedUpReferral') !== null : false

	const isFloatingButtonVisible =
		!emailCapModalVisible &&
		showEmailCapModalShortcut &&
		sessionStorage.getItem(eventNames.emailCapModalTriggerDone) !== null &&
		sessionStorage.getItem(eventNames.emailCapShortcutButtonTriggerDone) === null &&
		localStorage.getItem('email') === null &&
		heroWiped

	if (showReferralEcap) {
		return (
			<div id="email_cap_root">
				<ReferralModal
					modalVisible={!signedUpReferral && referralModalVisible}
					currency={currency}
					saleName={settings.saleName ?? ''}
					handleClose={() => {
						setReferralModalVisible(false)
						sessionStorage.setItem('signedUpReferral', 'true')
					}}
				/>
			</div>
		)
	}

	// Only show the chronotype AB testquiz if it's not a sleep elixir pdp and not a vanity page
	if (ecapVariant && !isSleepElixirPDP && !isVanity && !showExitIntent) {
		return (
			<div id="email_cap_root">
				<ChronotypeQuiz {...modalData} />
				<FloatingButton
					isVisible={isFloatingButtonVisible}
					isPDP={pathname.includes('/product')}
				/>
			</div>
		)
	}

	return (
		<div id="email_cap_root">
			{showExitIntent ? (
				<ExitIntentModal
					key={key}
					{...modalData}
				/>
			) : isVanity ? (
				<MiniModal
					key={key}
					{...modalData}
				/>
			) : (
				<QuizWithImage
					key={key}
					{...modalData}
				/>
			)}

			<FloatingButton
				isVisible={isFloatingButtonVisible}
				isPDP={pathname.includes('/product')}
			/>
		</div>
	)
}

export default EmailCapContainer
