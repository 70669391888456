import { PartnerVanities } from './types'

export const partnerVanities: PartnerVanities = {
	redGerard: {
		promoCode: 'redgerard',
		saleName: 'Red Gerard exclusive',
		emailCapSettings: {
			title: 'Unlock up to',
			subtitle: '',
			description: "Red Gerard's secret to make everyday feel like powder day: the Pod. Enter your email to start your sleep fitness journey.",
			imageSrc: 'https://eightsleep.imgix.net/BLATT--Red-453A6829.jpg?v=1670879192',
			imageAlt: 'Red Gerard',
		},
		partnerName: 'Red Gerard',
		pathMatch: (pathname: string) => pathname === '/red/' || pathname === '/red',
	},
	strava: {
		promoCode: 'strava15',
		saleName: 'Strava exclusive',
		emailCapSettings: {
			title: '',
			subtitle: '',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/strava/strava-ecap.jpg',
			imageAlt: 'Strava caption',
		},
		partnerName: 'Strava',
		pathMatch: (pathname: string) => pathname.includes('/strava'),
		getTitleWithAmount: (amount: string) => `Get ${amount} off`,
	},
	efProcycling: {
		promoCode: 'efcycling2024',
		saleName: 'EF Pro Cycling exclusive',
		emailCapSettings: {
			title: '',
			subtitle: '',
			imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/ef-ecap-new-logo-2025.jpg',
			imageAlt: 'EF Pro Cycling caption',
		},
		partnerName: 'EF Pro Cycling',
		pathMatch: (pathname: string) => pathname.includes('/efprocycling'),
	},
}
