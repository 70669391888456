/* eslint-disable @next/next/no-img-element */
import React, { useCallback, useEffect, useState } from 'react'
import styles from './QuizEcap.module.scss'
import { Img } from 'components/basic/Img'
import { Complete, Question, QuestionAbbr, quizData } from 'emailcaps/QuizWithImage/Complete/Complete'
import { klaviyoTrackQuizAnswers } from 'events/klaviyo'
import { trackSecondaryQuizEcapAnswer } from 'events/index'
import { amSecondaryQuizEcapShown, amSecondaryQuizEcapStepShown } from 'events/amplitude'
import { datadogReportError } from 'events/datadog'
import { useStore } from 'stores/StoreProvider'

const quizQuestion = 'What’s your biggest sleep challenge?'

export const QuizEcap = () => {
	const emailCapModalVisible = useStore((state) => state.modal.emailCapModalVisible)
	const modalShowQuizEcap = useStore((state) => state.modal.showQuizEcap)
	const dismissQuizEcap = useStore((state) => state.modal.dismissQuizEcap)

	const showQuizEcap = modalShowQuizEcap && !emailCapModalVisible

	const [answer, setAnswer] = useState<QuestionAbbr>(null)
	const handleQuizAnswer = useCallback((abbr: QuestionAbbr) => {
		try {
			klaviyoTrackQuizAnswers({
				Pain_point_1: abbr,
				Quiz_question_type: quizQuestion,
				Snorer: abbr === QuestionAbbr.Snore ? 'Y' : '',
			})
			trackSecondaryQuizEcapAnswer(quizData.get(abbr).question, quizQuestion)
			amSecondaryQuizEcapStepShown(2)
		} catch (error) {
			datadogReportError(error, 'Error tracking quiz answer')
		}
		setAnswer(abbr)
	}, [])

	useEffect(() => {
		if (showQuizEcap) {
			amSecondaryQuizEcapShown()
			amSecondaryQuizEcapStepShown(1)
		}
	}, [showQuizEcap])

	if (!showQuizEcap) return null

	return (
		<div className={styles.root}>
			<button
				aria-label="Close modal button"
				onClick={() => dismissQuizEcap()}
				className={`${styles.close_button}`}
			>
				<img
					aria-label={'Close'}
					src="https://eightsleep.imgix.net/close_d716c4c1-e156-409d-93c2-92907327e991.svg?v=1679385254"
					alt="Close popup icon"
				/>
			</button>
			<div className={styles.img}>
				<Img
					src="https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/quiz-ecap-hub.png"
					alt="Eight Sleep Hub"
				/>
			</div>
			<div className={styles.container}>
				{answer ? (
					<Complete
						answer={answer}
						closeModal={() => dismissQuizEcap()}
					/>
				) : (
					<Question
						hidePill={true}
						quizQuestion={quizQuestion}
						answerSubmit={handleQuizAnswer}
					/>
				)}
			</div>
		</div>
	)
}

export default QuizEcap
