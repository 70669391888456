'use client'

import { getNormalizedURL } from 'components/_utils/urlUtils'
import { ClientOnly } from 'components/ClientOnly'
import { EmailCapContainer } from 'emailcaps/EmailCapContainer'
import QuizEcap from 'emailcaps/QuizEcap/QuizEcap'
import PrimaryEmailCapWaypoint from 'emailcaps/SecondaryEmailCapModal/PrimaryEmailCapWaypoint'
import { useParams, usePathname } from 'next/navigation'
import { getSafeLocale } from 'utils/internationalization'
import { FC } from 'react'

export const EcapWrapper: FC = () => {
	const params = useParams()
	const pathname = usePathname() ?? ''
	const locale = getSafeLocale(params)
	const normalizedPath = getNormalizedURL(locale, pathname, params ?? undefined)
	let canonicalUrl = normalizedPath
	switch (pathname) {
		case '/[experiments]/[locale]/pod-prospecting':
		case '/[experiments]/[locale]/pod-prospecting-yt':
		case '/[experiments]/[locale]/cooling-sleep':
			canonicalUrl = '/product/pod-cover/'
			break
		case '/[experiments]/[locale]':
			canonicalUrl = '/'
			break
	}

	let hideEcap = false

	if (typeof window !== 'undefined') {
		hideEcap = window.sessionStorage.getItem('hide_ecap') !== null
		hideEcap = hideEcap || window.localStorage.getItem('authToken') !== null
	}

	const showWaypoint = shouldShowEcap(canonicalUrl, hideEcap)

	if (!showWaypoint) {
		return null
	}

	const primaryEmailCapTriggerPoint = 'calc(100vh + 10px)'

	return (
		<ClientOnly>
			<PrimaryEmailCapWaypoint
				delay={calculateDelay(canonicalUrl)}
				top={primaryEmailCapTriggerPoint}
			/>
			<EmailCapContainer />
			<QuizEcap />
		</ClientOnly>
	)
}

const calculateDelay = (canonicalUrl: string): number => {
	const googleLP = /product\/(pod4|pod4ultra)/.test(canonicalUrl)

	if (googleLP) return 5

	return 3
}

const shouldShowEcap = (canonicalUrl: string, hideEcap: boolean): boolean => {
	const eoyURL = canonicalUrl.includes('eoy20')

	return (
		!canonicalUrl.startsWith('/membership-details') &&
		!canonicalUrl.startsWith('/app-terms-conditions') &&
		!canonicalUrl.startsWith('/aceyoursleep') &&
		!canonicalUrl.startsWith('/sms-terms') &&
		!canonicalUrl.startsWith('/press') &&
		!canonicalUrl.startsWith('/referral-terms-conditions') &&
		!canonicalUrl.startsWith('/terms-and-conditions') &&
		!canonicalUrl.startsWith('/warranty') &&
		!canonicalUrl.startsWith('/404') &&
		!canonicalUrl.startsWith('/blog') &&
		!canonicalUrl.startsWith('/truemed') &&
		!canonicalUrl.startsWith('/pod-quiz') &&
		!canonicalUrl.includes('8ball') &&
		!canonicalUrl.includes('eoy20') &&
		!canonicalUrl.includes('sleepchemistryquiz') &&
		!canonicalUrl.includes('sleepreport') &&
		!canonicalUrl.includes('checkout') &&
		!canonicalUrl.includes('gsap') &&
		!canonicalUrl.startsWith('/affirm') &&
		!canonicalUrl.startsWith('/thank_you') &&
		!canonicalUrl.includes('pod4-') &&
		!canonicalUrl.includes('login') &&
		!canonicalUrl.includes('sleep-elixir-waitlist') &&
		!canonicalUrl.includes('sleep-elixir-management') &&
		!canonicalUrl.includes('athletes') &&
		!hideEcap &&
		!eoyURL
	)
}
