import React from 'react'
import { Input } from '../Input'
import styles from '../MiniModal.module.scss'
import { Button } from 'components/WebEv/Buttons'
import { Img } from 'components/basic/Img'
import cx from 'classnames'
import { CompleteUpdated, QuestionAbbr, QuestionWithSubmit } from 'emailcaps/QuizWithImage/Complete/Complete'
import { Type } from 'components/Type'

interface Props {
	title: string
	description: string
	sms_description: string
	subtitle?: string
	imgUrl: string
	imgAlt: string
	step: string
	email: string
	phone: string
	isEmailInvalid: boolean
	isPhoneInvalid: boolean
	answer: QuestionAbbr
	ecapTitle?: string
	handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	handlePhoneChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	handleEmailSubmit: (event: React.FormEvent<HTMLFormElement>) => void
	handlePhoneSubmit: (event: React.FormEvent<HTMLFormElement>) => void
	handleQuizComplete: (abbr: QuestionAbbr) => void
	amount?: string
	closeModal: () => void
	buttonText: string
	dismissText: string
	partnerName: string
	saleName: string
	isQuizFirst?: boolean
	learnMore: () => void
}

const Content: React.FC<Props> = (props: Props) => {
	return (
		<div className={cx(styles.root, styles.root_quiz)}>
			<div className={styles.image_container}>
				<Img
					src={props.imgUrl}
					alt={props.imgAlt}
				/>
			</div>
			<div className={cx(styles.cap_container, styles.cap_container_quiz)}>
				{props.step === 'email' && (
					<div className={styles.ecap_container}>
						<img
							className={`${styles.logo}`}
							src="https://eightsleep.imgix.net/logo_0c17cdd4-7930-4520-b7a2-41331b22b2e2.svg?v=1619637907"
							alt="Eightsleep logo"
						/>
						<div className={styles.info_container_ecap}>
							<div>
								<p className={styles.suptitle}>{props.saleName}</p>
								{props.ecapTitle && (
									<Type.Headline3
										as={'h2'}
										className={styles.promoTitle}
									>
										{props.ecapTitle}
									</Type.Headline3>
								)}
							</div>
							<Type.Headchapter className={cx(styles.promoDescription, styles.eCapPromoDescription)}> {props.description}</Type.Headchapter>
						</div>
						<div>
							<Input
								email={props.email}
								isInvalid={props.isEmailInvalid}
								handleChange={props.handleEmailChange}
								handleSubmit={props.handleEmailSubmit}
								autoComplete="email"
								cta={props.buttonText}
								className={styles.email_input}
								placeholder="Email *"
							/>

							<Button.Empty
								id={'email-cap-dismiss'}
								className={styles.button}
								onClick={props.closeModal}
							>
								{props.dismissText}
							</Button.Empty>
						</div>
						<p className={styles.disclaimer}>
							By continuing, you accept our privacy policy. Your personal data will be processed so that we can contact you about our products and send you surveys via e-mail. Read our Privacy Policy
							for more information.
						</p>
					</div>
				)}
				{props.step === 'phone' && (
					<div className={styles.ecap_container}>
						<img
							className={`${styles.logo}`}
							src="https://eightsleep.imgix.net/logo_0c17cdd4-7930-4520-b7a2-41331b22b2e2.svg?v=1619637907"
							alt="Eightsleep logo"
						/>
						<div className={styles.info_container_ecap}>
							<div>
								<p className={styles.suptitle}>{props.saleName}</p>
								<Type.Headline3
									as={'h2'}
									className={styles.promoTitle}
								>
									{props.subtitle}
								</Type.Headline3>
							</div>
							<p className={cx(styles.promoDescription, styles.eCapPromoDescription)}>{props.sms_description}</p>
						</div>
						<div>
							<Input
								email={props.phone}
								isInvalid={props.isPhoneInvalid}
								error={props.isPhoneInvalid && 'Phone is invalid'}
								handleChange={props.handlePhoneChange}
								handleSubmit={props.handlePhoneSubmit}
								autoComplete="phone"
								// eslint-disable-next-line jsx-a11y/no-autofocus
								autoFocus={true}
								cta={`Get offer`}
								placeholder="Phone number"
							/>
							<Button.Empty
								id={'sms-cap-dismiss'}
								className={styles.button}
								onClick={props.closeModal}
							>
								{props.dismissText}
							</Button.Empty>
						</div>
						<p className={styles.disclaimer}>
							By continuing, you accept our privacy policy. Your personal data will be processed so that we can contact you about our products and send you surveys via e-mail. Read our Privacy Policy
							for more information.
						</p>
					</div>
				)}

				{props.step === 'quiz' && (
					<>
						{props.isQuizFirst && (
							<div className={styles.info_container}>
								<img
									className={`${styles.logo}`}
									src="https://eightsleep.imgix.net/logo_0c17cdd4-7930-4520-b7a2-41331b22b2e2.svg?v=1619637907"
									alt="Eightsleep logo"
								/>
								<p className={styles.suptitle}>{props.saleName}</p>
								<Type.Headline3
									as={'h2'}
									className={styles.promoTitle}
								>
									{props.title}
								</Type.Headline3>
							</div>
						)}
						<QuestionWithSubmit
							questionClass={styles.question}
							hidePill={props.isQuizFirst}
							answerSubmit={props.handleQuizComplete}
						/>
						<Button.Empty
							id={'quiz-dismiss'}
							className={styles.button}
							onClick={props.closeModal}
						>
							{props.dismissText}
						</Button.Empty>
					</>
				)}

				{props.step === 'complete' && (
					<div className={styles.ecap_container}>
						<img
							className={`${styles.logo}`}
							src="https://eightsleep.imgix.net/logo_0c17cdd4-7930-4520-b7a2-41331b22b2e2.svg?v=1619637907"
							alt="Eightsleep logo"
						/>
						<div>
							<p className={styles.suptitle}>{props.saleName}</p>{' '}
							<CompleteUpdated
								answer={props.answer}
								closeModal={props.learnMore}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default Content
