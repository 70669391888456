'use client'

import React, { useEffect, useRef, useState } from 'react'
import {
	KlaviyoSMSRequest,
	trackQuizAnswer,
	trackQuizShown,
	trackSubscribeViaEmailCap,
	trackSubscribeViaSMSCap,
	trackViewedEmailCap,
} from 'events/index'
import { stripPhone, validateEmail, validatePhone } from 'utils/validators'
import { quizQuestion, getStepConfigs, SMSError } from './ModalScreens'
import { klaviyoTrackQuizAnswers } from 'events/klaviyo'
import { setDataLayerVariable } from 'events/ga'
import { sha256 } from 'js-sha256'
import Head from 'next/head'
import cx from 'classnames'
import corestyles from '../core/styles.module.scss'
import styles from './ChronotypeQuiz.module.scss'
import { AFFIRM_PROMO_QUERY, EMAIL_PROMO_QUERY, SMS_PROMO_QUERY } from 'config/url_promos'
import { useRouter } from 'next/navigation'
import { useClickOutsideModal } from 'hooks/useClickOutsideModal'
import FocusTrapWrapper from 'components/FocusTrapWrapper'
import { Button } from 'components/Phantom/Button'
import { Icon } from 'components/Phantom/Icon'
import { Img } from 'components/basic/Img'
import { useStore } from 'stores/StoreProvider'
import { CaptureLocation } from 'utils/events'
import { MiniModalProps } from 'emailcaps/types'

const handlePromo = (): string => {
	const email = localStorage.getItem('email') ?? null
	const phone = localStorage.getItem('phone') ?? null

	const phoneExists = phone !== null
	const emailExists = email !== null

	const affirmEmailPromo = sessionStorage.getItem('affirmpresidentsdayemail') === 'true'
	if (affirmEmailPromo) return ''

	if (sessionStorage.getItem('affirmpresidentsday') === 'true' && (phoneExists || emailExists)) {
		sessionStorage.removeItem('affirmpresidentsday')
		return `${window.location.pathname}?${AFFIRM_PROMO_QUERY}`
	} else if (phoneExists) {
		return `${window.location.pathname}?${SMS_PROMO_QUERY}`
	} else if (emailExists) {
		return `${window.location.pathname}?${EMAIL_PROMO_QUERY}`
	}

	return ''
}

export const ChronotypeQuiz: React.FC<MiniModalProps> = (props) => {
	const router = useRouter()
	const [stepIndex, setStepIndex] = useState(0)
	const [email, setEmail] = useState('')
	const [loading, setLoading] = useState(false)
	const [phone, setPhone] = useState('')
	const [smsError, setSmsError] = useState<SMSError>({ error: false, message: '' })
	const [answer, setAnswer] = useState<string>()
	const pageWrapper = useRef<HTMLDivElement | null>(null)
	const captureLocation = props.captureLocation
	const showSMSStep = props.region === 'us' || props.region === ''

	const hideEmailCapModal = useStore((state) => state.modal.hideEmailCapModal)

	const incrementStep = (): void => {
		setStepIndex(stepIndex + 1)
	}

	const modalName = 'Email Cap - Improve Sleep'

	useEffect(() => {
		if (props.emailCapModalVisible) {
			trackViewedEmailCap(modalName)
		}
	}, [props.emailCapModalVisible])

	const handleClose = (): void => {
		hideEmailCapModal()
		const promoPath = handlePromo()
		if (promoPath) {
			router.push(promoPath)
		}
	}

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setEmail(event.target.value)
	}

	const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setPhone(event.target.value)
	}

	const emailHandler = async (): Promise<void> => {
		const validEmail = validateEmail(email)
		const properties = {
			email: email,
			emailCapType: `2Step - ${modalName}`,
			location: CaptureLocation.MODAL,
			captureLocation: captureLocation,
			image: props.imgUrl,
			description: props.description,
			vanityLink: window.location.href,
			totalDiscount: props.totalDiscount,
			discountCode: props.discountCode,
		}

		if (validEmail) {
			await trackSubscribeViaEmailCap(properties)
		}
	}

	const phoneHandler = async (): Promise<
		KlaviyoSMSRequest | { error: boolean; message: string; body: null }
	> => {
		const strippedPhone = stripPhone(phone)
		const passesValidation = validatePhone(strippedPhone)

		const properties = {
			email: email,
			phone: '+1' + strippedPhone,
			emailCapType: `2Step - ${modalName}`,
			location: 'React Modal',
			captureLocation: captureLocation,
			image: props.imgUrl,
			description: props.description,
			amount: props.totalDiscount ?? '',
			code: props.discountCode ?? '',
			region: props.region ?? '',
		}

		if (passesValidation) {
			return await trackSubscribeViaSMSCap(properties)
		} else {
			return { error: true, message: 'Wrong phone number format', body: null }
		}
	}

	const onSubmitQuestionAnswer = (answer: string): void => {
		incrementStep()
		setAnswer(answer)
		trackQuizAnswer(answer, quizQuestion.title)
	}

	const handleEmailSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
		event.preventDefault()
		setLoading(true)
		await emailHandler()
		setLoading(false)
		localStorage.setItem('email', email)
		setDataLayerVariable('customer_email', email)
		setDataLayerVariable('hashed_customer_email', sha256(email))
		klaviyoTrackQuizAnswers({
			quiz_question: quizQuestion,
			quiz_answer: answer,
		})
		incrementStep()
	}

	const handlePhoneSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
		// phone number is optional in this flow
		if (!phone) {
			incrementStep()
			return
		}
		try {
			event.preventDefault()
			setSmsError({ error: false, message: '' })
			setLoading(true)
			const result = await phoneHandler()
			if (result.body === null || !result.body.success) {
				setSmsError({
					error: true,
					message: 'Oops, something is wrong with your phone number.',
				})
				setLoading(false)
				return
			}
			setLoading(false)

			incrementStep()
			setTimeout(handleClose, 20000)

			localStorage.setItem('phone', phone)
			setDataLayerVariable('hashed_customer_phone', sha256(phone))
			trackQuizShown()
		} catch (error) {
			setSmsError({ error: true, message: 'Something went wrong, please try again.' })
			setLoading(false)
		}
	}

	const stepConfigs = getStepConfigs({
		loading,
		handleEmailChange,
		handleEmailSubmit,
		handlePhoneChange,
		handlePhoneSubmit,
		onSubmitQuestionAnswer,
		handleClose,
		showSMSStep,
		smsError,
		totalDiscount: props.totalDiscount ?? '',
		answer,
	})

	useClickOutsideModal(pageWrapper as React.RefObject<HTMLDivElement>, handleClose, false)

	const desktopImg =
		'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/feb-26-ecap-hub.jpg'
	const mobileImg = desktopImg

	const renderCurrentStep = (): React.ReactNode => {
		const step = stepConfigs[stepIndex]
		return React.createElement(step.component, step.props)
	}

	const step = ['question', 'email', 'phone', 'complete'][stepIndex]

	return (
		<>
			<Head>
				<link
					rel="preload"
					as="image"
					href={mobileImg}
				/>
			</Head>
			<FocusTrapWrapper active={props.emailCapModalVisible}>
				<div
					className={cx(corestyles.container, { [corestyles.none]: !props.emailCapModalVisible })}
				>
					<div
						className={styles.modal_wrapper_content}
						ref={pageWrapper}
					>
						<Button.Empty
							id="ecap-quiz-close"
							type="button"
							aria-label="Close modal button"
							onClick={handleClose}
							className={styles.close_button}
						>
							<Icon name="CloseLight" />
						</Button.Empty>

						<div className={cx(styles.ecap_image_wrapper)}>
							<Img
								src={desktopImg}
								sources={[
									{ mediaQuery: '(min-width: 1023px)', src: desktopImg, dprHeight: 2000 },
									{ mediaQuery: '(max-width: 1023px)', src: mobileImg, dprHeight: 2000 },
								]}
								className={cx(styles.ecap_image, {
									[styles.ecap_image_email_step]: step === 'email',
									[styles.ecap_image_phone_step]: step === 'phone',
									[styles.ecap_image_complete_step]: step === 'complete',
								})}
								alt="Eight Sleep branding message"
								objectFit={'cover'}
								doNotCompress
							/>
						</div>
						{renderCurrentStep()}
					</div>
				</div>
			</FocusTrapWrapper>
		</>
	)
}

export default ChronotypeQuiz
