import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './Screens.module.scss'
import { Button } from 'components/WebEv/Buttons'
import { shuffleArray } from 'components/_utils/arrayUtils'
import { Type } from 'components/Type'
import { Button as BasicButton } from 'components/basic/Button'
import { Currency } from 'prices/types'
import { Icon } from 'components/Phantom/Icon'
import { LinkWithLocale } from 'components/basic/LinkWithLocale'
import check_anim from './check_anim.json'

export enum QuestionAbbr {
	Snore = 'Snore',
	Falling_staying_asleep = 'Falling_staying_asleep',
	None = 'None',
	Wake_up = 'Wake_up',
	HotCold = 'HotCold',
	RacingThoughts = 'RacingThoughts',
}

interface QuizItem {
	question: string
	description: string
	description2: string
	explanation: string
	abbr: QuestionAbbr
	quote: string
	author: string
	images: {
		desktopImage: string
		mobileImage: string
	}
}

interface WrapperProps {
	headerText: string
	title: string
	subtitle?: string
	dismissText?: string
	disclaimer?: string
	checkmark?: boolean
	children?: React.ReactNode
	closeModal?: () => void
	isSleepElixirPDP?: boolean
}

type QuestionProps = WrapperProps & {
	answerSubmit?: (abbr: QuestionAbbr) => void
	answer?: QuestionAbbr
	questionClass?: string
}

type EmailCaptureProps = WrapperProps & {
	handleEmailChange: (email: React.ChangeEvent<HTMLInputElement>) => void
	emailHandleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
	currency: Currency
	buttonText: string
}

type PhoneCaptureProps = WrapperProps & {
	handleSMSChange: (email: React.ChangeEvent<HTMLInputElement>) => void
	smsHandleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
	currency: Currency
	buttonText: string
	error?: string
}

type CompleteProps = WrapperProps & {
	buttonText: string
	quote: string
	author: string
}

export const quizData: Map<QuestionAbbr, QuizItem> = new Map([
	[
		QuestionAbbr.HotCold,
		{
			question: 'I feel hot or cold',
			description: '50% of wake ups are from sleeping at the wrong temperature',
			description2: 'Sleeping on the Pod is clinically proven to give you up to one more hour of sleep every night',
			explanation: 'Your solution to sleeping at the right temperature is here',
			abbr: QuestionAbbr.HotCold,
			quote: 'The Pod’s temperature control has been game-changing for my sleep.I wake up with way more energy.',
			author: 'Evan C.',
			images: {
				desktopImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/new-ecap-2-hotcold-desktop.jpg',
				mobileImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/Hot-cold.png',
			},
		},
	],
	[
		QuestionAbbr.Snore,
		{
			question: 'I snore',
			description: 'Snoring can reduce sleep efficiency by up to 20%',
			description2: 'Sleeping on the Pod is clinically proven to reduce snoring by up to 45%',
			explanation: 'Your solution to snoring is here',
			abbr: QuestionAbbr.Snore,
			quote: 'Better quality sleep and starting my day with more energy. Also my nose and sinuses hurt less because I am snoring less.',
			author: 'Ben S.',
			images: {
				desktopImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/new-ecap-2-snore-desktop.jpg',
				mobileImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/Snoring+Image.png',
			},
		},
	],
	[
		QuestionAbbr.Falling_staying_asleep,
		{
			question: "I can't fall asleep",
			description: 'Poor sleep boosts stress levels by up to 54%',
			description2: 'Sleeping on the Pod is clinically proven to help you fall asleep up to 44% faster',
			explanation: 'Your solution to falling asleep faster is here',
			abbr: QuestionAbbr.Falling_staying_asleep,
			quote: "I wasn't sure the Pod would make a difference, but it's been night and day. I fall asleep faster, stay asleep longer, and get 2 extra hours of rest.",
			author: 'Sara H.',
			images: {
				desktopImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/new-ecap-2-fall-desktop.jpg',
				mobileImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/Fallasleep.png',
			},
		},
	],
	[
		QuestionAbbr.Wake_up,
		{
			question: 'I wake up often',
			description: 'Interrupted sleep lowers energy levels by 30%',
			description2: 'Sleeping on the Pod is clinically proven to improve your sleep quality by up to 25%',
			explanation: 'Your solution to sleeping through the night is here',
			abbr: QuestionAbbr.Wake_up,
			quote: "I wasn't sure the Pod would make a difference, but it's been night and day. I fall asleep faster, stay asleep longer, and get 2 extra hours of rest.",
			author: 'Sara H.',
			images: {
				desktopImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/new-ecap-2-asleep-desktop.jpg',
				mobileImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/Stayasleep.png',
			},
		},
	],
	[
		QuestionAbbr.RacingThoughts,
		{
			question: 'I have racing thoughts at bedtime',
			description: 'Poor sleep boosts stress levels by up to 54%',
			description2: 'Sleeping on the Pod is clinically proven to give you up to one more hour of sleep every night',
			explanation: 'Your solution to better sleep is here',
			abbr: QuestionAbbr.RacingThoughts,
			quote: '',
			author: '',
			images: {
				desktopImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/new-ecap-1-desktop.png',
				mobileImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/new-ecap-1-mobile.png',
			},
		},
	],
	[
		QuestionAbbr.None,
		{
			question: 'I don’t have any sleep concerns',
			description: 'Poor sleep boosts stress levels by up to 54%',
			description2: 'Sleeping on the Pod is clinically proven to give you up to one more hour of sleep every night',
			explanation: 'Your solution to better sleep is here',
			abbr: QuestionAbbr.None,
			quote: "I wasn't sure the Pod would make a difference, but it's been night and day. I fall asleep faster, stay asleep longer, and get 2 extra hours of rest.",
			author: 'Sara H.',
			images: {
				desktopImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/new-ecap-1-desktop.png',
				mobileImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-ecap-abc/new-ecap-1-mobile.png',
			},
		},
	],
])

const QUIZ_DATA = Array.from(quizData.values())

const getShuffledQuestions = () => {
	const withoutNone = QUIZ_DATA.filter((q) => q.abbr !== QuestionAbbr.None && q.abbr !== QuestionAbbr.RacingThoughts)
	return shuffleArray(withoutNone)
}

const getSleepElixirQuestions = () => {
	const shuffled = shuffleArray(QUIZ_DATA.filter((q) => q.abbr === QuestionAbbr.Falling_staying_asleep || q.abbr === QuestionAbbr.Wake_up || q.abbr === QuestionAbbr.RacingThoughts))
	shuffled.push(QUIZ_DATA.find((q) => q.abbr === QuestionAbbr.None))
	return shuffled
}

const LottieWrapper = () => {
	const [Lottie, setLottie] = useState(undefined)

	async function loadLottie() {
		const dependency = await import('lottie-react')
		setLottie(dependency)
	}

	useEffect(() => {
		loadLottie()
	}, [])

	if (Lottie) {
		return <LottieCheckmark Lottie={Lottie} />
	}

	return null
}

const LottieCheckmark = ({ Lottie }: { Lottie: any }) => {
	const LottieObject = Lottie.useLottie({
		animationData: check_anim,
		loop: false,
		autoplay: true,
	})

	return <div className={styles.checkmark}>{LottieObject.View}</div>
}

export const Wrapper = (props: WrapperProps) => {
	return (
		<div className={styles.ecap_content_wrapper}>
			<div>{props.checkmark && <LottieWrapper />}</div>
			<Type.Eyebrow className={styles.eyebrow}>{props.headerText}</Type.Eyebrow>
			<Type.Headline4 className={styles.headline}>{props.title}</Type.Headline4>
			{props.subtitle && <Type.Body1 className={styles.quiz_question}>{props.subtitle}</Type.Body1>}
			<div className={styles.children_wrapper}>
				{props.children}
				<div>
					{props.dismissText && (
						<BasicButton
							content={props.dismissText}
							className={styles.dismiss}
							unstyled
							onClick={() => props.closeModal()}
						/>
					)}
				</div>
			</div>
			{props.disclaimer && <Type.SmallPrint className={styles.disclaimer}>{props.disclaimer}</Type.SmallPrint>}
		</div>
	)
}

export const QuestionNew: FC<QuestionProps> = (props) => (
	<Wrapper
		{...props}
		closeModal={() => props.answerSubmit(QuestionAbbr.None)}
		dismissText={props.isSleepElixirPDP ? '' : props.dismissText}
	>
		<div className={styles.quiz}>
			{(props.isSleepElixirPDP ? getSleepElixirQuestions() : getShuffledQuestions())
				.filter((it) => !!it)
				.map((data, index) => (
					<Button.Secondary
						key={`quiz-question-${index}`}
						id={`quiz-question-${index}`}
						className={styles.button}
						onClick={() => props.answerSubmit(data.abbr)}
					>
						<Type.Body1 as="span">{data.question}</Type.Body1>
					</Button.Secondary>
				))}
		</div>
	</Wrapper>
)

export const EmailCaptureNew: FC<EmailCaptureProps> = (props) => (
	<Wrapper {...props}>
		<form
			className={styles.email_form}
			onSubmit={props.emailHandleSubmit}
		>
			<input
				required
				type="email"
				placeholder="Enter your email *"
				onChange={props.handleEmailChange}
				aria-label="Your email address input"
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus
			/>
			<Button.Primary
				id={'email-submit-button'}
				className={styles.submitbutton}
				type="submit"
			>
				{props.buttonText}
				<Icon
					className={styles.arrow}
					size={24}
					color="#FFF"
					name={'ArrowRightDark'}
				/>
			</Button.Primary>
		</form>
	</Wrapper>
)

export const PhoneCaptureNew: FC<PhoneCaptureProps> = (props) => (
	<Wrapper {...props}>
		<form
			className={styles.email_form}
			onSubmit={props.smsHandleSubmit}
		>
			<input
				required
				type="tel"
				placeholder="Enter your phone number *"
				onChange={props.handleSMSChange}
				aria-label="Your phone number input"
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus
			/>
			{props.error && <p className={styles.error}>{props.error}</p>}
			<Button.Primary
				id={'phone-submit-button'}
				className={styles.submitbutton}
				type="submit"
			>
				{props.buttonText}
				<Icon
					className={styles.arrow}
					size={30}
					color="#FFF"
					name={'ArrowRightDark'}
				/>
			</Button.Primary>
		</form>
	</Wrapper>
)

const CLOSE_URLS = ['/product/pod-cover/', '/product/pod4ultra/', '/product/pod4/', '/product/sleep-elixir/']

export const CompleteNew: FC<CompleteProps> = (props) => {
	const getButtonAction = useCallback(() => {
		const pathname = typeof window !== 'undefined' ? window.location.pathname : ''
		if (CLOSE_URLS.some((url) => pathname.includes(url))) {
			return {
				href: undefined,
				onClick: props.closeModal,
			}
		}

		return {
			onClick: props.closeModal,
			href: undefined,
		}
	}, [props.closeModal])

	return (
		<Wrapper {...props}>
			<Button.Primary
				id={'ecap-quiz-final-step'}
				className={styles.submitbutton}
				{...getButtonAction()}
			>
				{props.buttonText}
				<Icon
					className={styles.arrow}
					size={30}
					color="#FFF"
					name={'ArrowRightDark'}
				/>
			</Button.Primary>
			{props.isSleepElixirPDP ? (
				<Type.Body2 className={styles.highlight_small}>{props.quote}</Type.Body2>
			) : (
				<div className={styles.highlight}>
					<div>
						<Stars count={5} />
						<Type.Eyebrow className={styles.star_reviews}>
							<LinkWithLocale href="/wall-of-love">2,000 5 star reviews</LinkWithLocale>
						</Type.Eyebrow>
					</div>
					<Type.Headchapter className={styles.highlight_text}>“{props.quote}”</Type.Headchapter>
					<Type.Body3>- {props.author}</Type.Body3>
				</div>
			)}
		</Wrapper>
	)
}

const Stars: FC<{ count: number }> = (props) => {
	return (
		<div className={styles.stars}>
			{Array(props.count)
				.fill(1)
				.map((_, i) => (
					<Icon
						name={'StarDark'}
						key={i}
						color={'#1862FF'}
					/>
				))}
		</div>
	)
}
