import { useSearchParams } from 'next/navigation'

export const useUTMParams = () => {
	const params = useSearchParams()
	return getUTMParams(params)
}

export const getUTMParams = (params?: URLSearchParams) => {
	const searchParams = params ?? new URLSearchParams(window.location.search)
	return {
		utmMedium: searchParams.get('utm_medium'),
		utmSource: searchParams.get('utm_source'),
		utmContent: searchParams.get('utm_content'),
		utmCampaign: searchParams.get('utm_campaign'),
	}
}
