'use client'

import { ClientOnly } from 'components/ClientOnly'
import LocalizeModal from 'components/LocalizeModal/LocalizeModal'
import { usePathname } from 'next/navigation'
import { FC } from 'react'

export const LocalizeModalWrapper: FC = () => {
	const pathname = usePathname() ?? ''

	const eoyURL = pathname.includes('eoy20')
	if (eoyURL) {
		return null
	}

	return (
		<ClientOnly>
			<LocalizeModal />
		</ClientOnly>
	)
}
