import cx from 'classnames'
import { getCountries, getCountry } from 'components/NavBar/CountryPicker/flags'
import { Select } from 'components/Select'
import { Button } from 'components/WebEv/Buttons'
import { useClickOutsideModal } from 'hooks/useClickOutsideModal'
import { useParams, usePathname } from 'next/navigation'
import React, { useEffect, useRef, useState } from 'react'
import { useStore } from 'stores/StoreProvider'
import { useCurrentRegion } from 'stores/settings'
import { getSafeLocale, redirectToRegion } from 'utils/internationalization'
import styles from './LocalizeModal.module.scss'

export interface LocalizeModalProps {
	className?: string
}

const LOCALIZE_LANGUAGES = [
	{
		value: 'en',
		name: 'English',
	},
	{
		value: 'de',
		name: 'Deutsch',
	},
	{
		value: 'es',
		name: 'Español',
	},
	{
		value: 'fr',
		name: 'Français',
	},
	{
		value: 'it',
		name: 'Italiano',
	},
]

const bannedPages = ['/strava/']

const ALL_COUNTRIES = getCountries(null)

export const LocalizeModal: React.FC<LocalizeModalProps> = (props: LocalizeModalProps) => {
	const currentRegion = useCurrentRegion()
	const localizeReady = useStore((state) => state.settings.localizeReady)
	const currentCountry = getCountry(currentRegion)
	const [selectedLanguage, setSelectedLanguage] = useState(window.Localize?.getLanguage() || 'en')
	const [selectedCountry, setSelectedCountry] = useState(currentCountry)
	const localizeModalVisible = useStore((state) => state.modal.localizeModalVisible)
	const toggleLocalizeModal = useStore((state) => state.modal.toggleLocalizeModal)

	const pathname = usePathname()
	const params = useParams()

	const locale = getSafeLocale(params)
	const pageWrapper = useRef(null)

	const handleClose = () => {
		toggleLocalizeModal()
		localStorage.setItem('confirmedRegion', 'true')
	}

	useClickOutsideModal(pageWrapper, handleClose, true)

	useEffect(() => {
		setSelectedLanguage(window.Localize?.getLanguage() || 'en')
	}, [localizeReady])

	if (!localizeReady) return null

	if (bannedPages.some((page) => pathname.includes(page))) return null

	const countriesItems = ALL_COUNTRIES.map((item, i) => ({
		value: item.region,
		menuComponent: (
			<div
				className={styles.menu_item}
				key={`${item.region}-country-menu-${i}`}
			>
				<svg viewBox="0 0 21 15">{item.flag}</svg>
				<span>{item.name}</span> <span>({item.currencyTitle})</span>
			</div>
		),
		displayComponent: (
			<div
				className={styles.display_item}
				key={`${item.region}-country-display-${i}`}
			>
				<small>Choose Region</small>
				<span>{item.name}</span>
			</div>
		),
	}))

	const languagesItems = LOCALIZE_LANGUAGES.map((item, i) => ({
		value: item.value,
		menuComponent: (
			<div
				className={styles.menu_item}
				key={`${item.value}-language-menu-${i}`}
			>
				{item.name}
			</div>
		),
		displayComponent: (
			<div
				className={styles.display_item}
				key={`${item.value}-language-display-${i}`}
			>
				<small>Choose language</small>
				<span>{item.name}</span>
			</div>
		),
	}))

	const onCountrySelect = (value: string | number | undefined) => {
		setSelectedCountry(getCountry(value as string))
	}

	const onLanguageSelect = (value: string | number | undefined) => {
		setSelectedLanguage(value as string)
	}

	const handleSubmit = () => {
		window.Localize?.setLanguage(selectedLanguage)
		handleClose()
		if (selectedCountry.region !== currentRegion) {
			redirectToRegion(selectedCountry.region, locale, pathname)
		}
	}

	const selectedCountryItem = countriesItems.find((m) => m.value === selectedCountry.region) || countriesItems[0]
	const selectedLanguageItem = languagesItems.find((m) => m.value === selectedLanguage) || languagesItems[0]

	return (
		<div
			className={cx('modal_container', props.className, { none: !localizeModalVisible })}
			translate="no"
		>
			<div
				className={styles.modal_content_container}
				ref={pageWrapper}
			>
				<Button.Empty
					id="close-localize-modal"
					aria-label="Close modal button"
					className={styles.close_button}
					onClick={handleClose}
				>
					{/* eslint-disable-next-line @next/next/no-img-element */}
					<img
						src="https://eightsleep.imgix.net/Close_Icon_1.png?v=1583943271"
						alt="Close Icon"
					/>
				</Button.Empty>
				<div className={styles.modal_content}>
					<p>Choose your region and language</p>
					<p>Please select the region to which your order will be sent. This will give you accurate pricing, delivery times and shipping costs.</p>
					<div>
						<Select
							items={countriesItems.filter((it) => it.value !== selectedCountry.region)}
							onSelection={onCountrySelect}
							selectedItem={selectedCountryItem}
							wrapperClassname={`${styles.select_wrapper} ${styles.first_select}`}
							allowEmpty={false}
							isError={false}
						/>
					</div>
					<div>
						<Select
							items={languagesItems.filter((it) => it.value !== selectedLanguage)}
							onSelection={onLanguageSelect}
							selectedItem={selectedLanguageItem}
							wrapperClassname={styles.select_wrapper}
							allowEmpty={false}
							isError={false}
						/>
					</div>
					<Button.Primary
						id={'save-localize'}
						className={styles.save_button}
						size={'large'}
						onClick={() => handleSubmit()}
					>
						Save
					</Button.Primary>
				</div>
			</div>
		</div>
	)
}

export default LocalizeModal
